import React from 'react'
import styles from './BlogDetails.module.css'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
// import Cookies from 'js-cookie'
import axios from 'axios'
import Navbar1 from '../../Components/Navbar1/Navbar1'


function BlogDetails() {
    const { id } = useParams();
    // const jwtToken = Cookies.get('jwtToken');
    const [blogDetails, setBlogDetails] = useState<any>([]);

    // const editor = useRef(null)

    useEffect(() => {
        const config: any = {
            method: 'get',
            url: `http://localhost:3006/blog/blogdetails/${id}`,
            headers: {
                Authorization: "vgz2MtyLpVKBx1FVZ176CNp7XWUPpikK",
                'Content-Type': 'application/json'
            }
        }

        axios(config)
            .then(res => {
                // console.log(res)
                const response = res?.data
                console.log(response)
                setBlogDetails(response)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])



    // const { title, author, quote, card_img, blog_img, description1, description2, description3 } = blogDetails[0]
    console.log(blogDetails, "blogDetails")


    return (



        <div className={styles.mainContainer}>
            <Navbar1 />
            <div className={styles.container}>
                {blogDetails.map((eachItem: any) => {
                    return (
                        <div key={eachItem.id} dangerouslySetInnerHTML={{ __html: eachItem.description1 }}>
                            {/* <img src={eachItem.blog_img} />
                            <h4>{eachItem.title}</h4>
                            <h5>By Author {eachItem.author}</h5>
                            <p>{eachItem.description1}</p>
                            <img src={eachItem.card_img} />
                            <p>{eachItem.description2}</p>

                            <p>{eachItem.description3}</p> */}


                        </div>
                    )
                })}



            </div>
        </div>
    )
}

export default BlogDetails
