import React from 'react'
import styles from './ExecutiveRentals.module.css';
import Navbar1 from '../../Components/Navbar1/Navbar1';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';


const logos = [
    { name: 'whistledrive-invesco-logo', id: 1 },
    { name: 'whistledrive-novartis-logo', id: 2 },
    { name: 'whistledrive-one-plus-logo', id: 3 },
    { name: 'whistledrive-examity-logo', id: 4 },
    { name: 'whistledrvie-L&T-logo', id: 5 },
    { name: 'whistledrive-ADP-logo', id: 6 },
]




const usageTypeContent = [
    { content: 'Executive Car Rental', img: 'Exclusice_car_rental', id: 1 },
    { content: 'Liesure & Luxury', img: 'Leisure_Luxury', id: 2 },
    { content: 'City Tour', img: 'City_Tour', id: 3 },
    { content: 'Events, Off-Sites & Conferences', img: 'Events_offsite_conference', id: 4 },
    { content: 'Airport & Hotel Transfer', img: 'Airport_HotelTransfer', id: 5 },
    { content: 'Car at Disposal', img: 'Car_at_Disposal', id: 6 },
    { content: 'Day Outing', img: 'Day_Outing', id: 7 },
    { content: 'Short & Long Period Rentals', img: 'Short_Long_Rentals', id: 8 },
]


function ExecutiveRenatls() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        arrows: true,
        // autoplaySpeed: 3000,
    };

    const settings1 = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        // autoplaySpeed: 3000,
    };


    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Executive-Retnals</title>
                <meta name="description"
                    content="Admin & Transport Managers can get everything done from our application."
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>

                    <div className={styles.mainContent}>
                        <div className={styles.subContent}>
                            <h5>All-in-one Solution</h5>
                            <h1 className={styles.fullStack}>Hassle-free Executive car rentals in your control</h1>
                            <p className={styles.paragraph}>Helping corporates and businesses streamline the transportation needs for all their executive visits and trips. </p>
                            <Link to="/contact-us">Contact Us</Link>
                        </div>
                        <div className={styles.mainImgContainer}>
                            <img src='../assests/images/ExecutiveRentals/executive-rentals.png' alt='executive-rentals' />
                        </div>
                    </div>
                    <div className={styles.brandContainer}>
                        <div className={styles.brandImgsContainer}>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                                ))}
                            </div>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                                ))}
                            </div>

                        </div>
                    </div>
                    <hr className={styles.line1} />
                    <div className={styles.integratedContainer}>
                        <h3>Personalized Transportation solutions for your every need!</h3>

                        <div className={styles.usageContainer}>
                            <img src='../assests/images/ExecutiveRentals/UsageTypeCar.png' alt='UsageTypeCar' />
                            <ul className={styles.integrateSolution}>
                                {usageTypeContent.map((eachItem) => {
                                    return (
                                        <li className={styles.solutionContainer} key={eachItem.id}>
                                            <img src={`../assests/images/ExecutiveRentals/${eachItem.img}.png`} alt='cars' />
                                            <p className={styles.paragraph}>
                                                {eachItem.content}
                                            </p>

                                        </li>
                                    )
                                })}

                            </ul>
                        </div>


                    </div>
                    {/* <div className={styles.redefineContainer}>
                        <div className={styles.redefineContent}>
                            <h4>Admin & Transport Managers can get everything done from our application</h4>
                            <p>Admin Managers/Transport team can stay informed all times. Our technology platform automates most of processes involved like</p>
                            <ul className={styles.redefineContentlist}>
                                {redefiningContent.map((eachItem: any) => {
                                    return (
                                        <li key={eachItem.id}>
                                            <span><i className={`fa fa-check ${styles.tickIcon}`}></i></span>
                                            {eachItem.text}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <img src='../assests/images/ExecutiveRentals/advance-tech.png' alt='advance-tech' />

                    </div> */}
                    <div className={styles.yourEmployeeContainer}>
                        <div className={styles.yourEmployeeContent}>
                            <h2>Creating a Seamless Experience with 100% Transparency</h2>
                            <p className={styles.paragraph}>Admin & Transport Managers can get everything done from our application</p>
                            <h5>Live Tracking</h5>
                            <p className={styles.paragraph}>
                                You can track the location of the car in real-time along with the smart safety & security features and an option for sharing your live location with your friends/family.
                            </p>
                            <h5>Trip Reading</h5>
                            <p className={styles.paragraph}>
                                The driver updates the vehicle odometer reading before starting the trip, including the odometer picture, helps in keeping the stakeholders informed. No chances for data manipulation!
                            </p>


                        </div>
                        <div className={styles.imgContainer}>
                            <Slider {...settings1}>
                                <div>
                                    <img src='../assests/images/ExecutiveRentals/transpercy-maps.png' alt='transpercy-maps' />


                                </div>
                                <div>
                                    <img src='../assests/images/ExecutiveRentals/advance-tech.png' alt='advance-tech' />

                                </div>

                            </Slider>
                        </div>
                    </div>
                    <div className={styles.whyUsContainer}>
                        <div className={styles.whyUsContent}>
                            <h3>Got multiple office locations?
                                We got you covered!</h3>
                            <img src='../assests/images/ExecutiveRentals/Location-Based.png' alt='Location-Based' />

                            <p className={styles.paragraph} style={{ textAlign: 'center' }}>
                                You can generate reports & invoices with respect to your office location and its GST details If you have multiple offices. These automated digital invoices help in speeding up the process while making the trip details transparent.
                            </p>

                        </div>


                    </div>

                    <div className={styles.carouselContainer}>
                        <h3>Our Extensive Fleet Network</h3>

                        <Slider {...settings}>
                            <div className={styles.carouselsImgContainer}>
                                <img src='../assests/images/ExecutiveRentals/sedan.png' alt='sedan' />
                                <h4>Economy Sedan{'\n'}(Max-4 Pax)</h4>
                                <p>(Dzire, Etios , Amaze, Xcent or similar)</p>

                            </div>
                            <div className={styles.carouselsImgContainer}>
                                <img src='../assests/images/ExecutiveRentals/premiumsedan.png' alt='sedan' />
                                <h4>Premium Sedan{'\n'}(Max-4 Pax)</h4>
                                <p>(City,ciaz or similar)</p>

                            </div>
                            <div className={styles.carouselsImgContainer}>
                                <img src='../assests/images/ExecutiveRentals/muv.png' alt='muv' />
                                <h4>MUV{'\n'}(Max-7 Pax)</h4>
                                <p>(Innove,Ertiga or similar)</p>

                            </div>
                            <div className={styles.carouselsImgContainer}>
                                <img src='../assests/images/ExecutiveRentals/suv.png' alt='suv' />
                                <h4>Premium Sedan{'\n'}(Max-7 Pax)</h4>
                                <p>(Innova crysta or similar)</p>

                            </div>

                        </Slider>

                    </div>


                    <ContactUsCard img={"ET_and_EL"} text={"Need help with Logistics or your Employees' Commute?"} />
                </div>

                <Footer />
            </div>

        </div>
    )
}

export default ExecutiveRenatls
