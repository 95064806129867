import React, { useState, useRef } from 'react'
import styles from './AboutUs.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1'
import TeamCard from '../../Components/TeamCard/TeamCard'
import { useFormik } from 'formik';
import * as yup from "yup";
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
// import FileBase64 from 'react-file-base64';

const initialValues = {
    name: '',
    mobile: '',
    email: '',
    file: '',
    message: ''
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    mobile: yup
        .string()
        .required()
        // .matches(/[1-9][0-9]{9}/, "Please enter valid mobile number")
        .min(10),
    email: yup.string().email().required("Email is Required"),
    file: yup.mixed().required('File is required'),
    message: yup.string().required()

})

const TeamDetails = [
    { img: 'Rakesh_Munnanuru', name: 'Rakesh Munnanooru', designation: 'Founder', id: 1 },
    { img: 'Prashanth', name: 'Prashanth M', designation: 'Chief Executive Officer (CEO)', id: 2 },
    { img: 'sireesha', name: 'Sireesha Tipparaju', designation: 'Chief Product Officer (CPO)', id: 3 },
    { img: 'Adinarayana_Chennupati', name: 'Adinarayana Chennupati', designation: 'Chief Operating Officer (COO)', id: 4 },
    // { img: 'Prabhakar', name: 'Prabhakar Pottapinjara', designation: 'Director of Enginnering', id: 5 },
    // { img: 'Rashmi_Sankrit', name: 'Rashmi Sankrit', designation: 'Head of Enterprise Sales', id: 6 },
    // { img: 'Mukesh_Kondal', name: 'Mukesh Kondal', designation: 'Lead, Business Development', id: 7 },
    // { img: 'Kubendra_Prasad', name: 'Kubendra Prasad', designation: 'Sales & Business Development Manager', id: 8 },
    // { img: 'Srinath_Sangana', name: 'Srinath Sangana', designation: 'General Manager - Operations, AP & Telangana', id: 9 },
    // { img: 'Jenish_Selva', name: 'Jenish Selva Shobu Chellam', designation: 'Head of Operations, Tamilnadu', id: 10 },
    // { img: 'Santhosh_Janardhan', name: 'Santhosh Janardhan Rao', designation: 'Head of Operations, Karnataka', id: 11 },
    // { img: 'Rajashekhar_Reddy', name: 'Rajashekhar Reddy Aileni', designation: 'Operations Manager, Andhra Pradesh', id: 12 },
    // { img: 'Komal_Singh', name: 'Komal Singh', designation: 'Regional Manager – Operations, Delhi NCR', id: 13 },
    // { img: 'Brahma_Kumar', name: 'Brahma Kumar Khamitkar', designation: 'Regional Manager – Operations, Pune', id: 14 },
    // { img: 'Harish_Vari', name: 'Harish Vari', designation: 'Sr. Operations Manager', id: 15 },
    // { img: 'Naveen_Reddy', name: 'Naveen Reddy Singireddy', designation: 'Operations Manager', id: 16 },
    // { img: 'Harika', name: 'Harika', designation: 'Inside Sales Manager', id: 17 },
    // { img: 'Sunil_Kumar', name: 'Sunil Kumar Polumuri', designation: 'Sr. Finance Manager', id: 18 },
    // { img: 'Rajeshwar_Reddy', name: 'Singireddy Rajeshwar Reddy', designation: 'Finance Manager', id: 19 },
    // { img: 'Pavan_Kumar', name: 'Pavan Kumar Nandivelugu', designation: 'Sr. Accounts Manager', id: 20 },
    // { img: 'Sravanthi_Tankasala', name: 'Sravanthi Tankasala', designation: 'HR Manager', id: 21 },
    // { img: 'Vidyanath_Reddy', name: 'Vidyanath Reddy', designation: 'IT Manager', id: 22 },
    // { img: 'Ajith_Reddy', name: 'Ajith Reddy D', designation: 'Executive – Legal & Compliance', id: 23 },
    // { img: 'Sampath_Vedantam', name: 'Sampath Vedantam', designation: 'Lead, Business Growth & New Initiatives', id: 24 },
    // { img: 'Anvesh_Munnanooru', name: 'Anvesh Munnanooru', designation: 'Growth Lead, Enterprise SaaS', id: 25 },
    // { img: 'vinay', name: 'Vinay Kumar Yarabolu', designation: 'Strategic Partnerships & Investments Analyst', id: 26 },
    // { img: 'Ramu_Peddagolla', name: 'Ramu Peddagolla', designation: 'Lead, Design', id: 27 },

]

function AboutUs() {

    const [fileName, setFileName] = useState<any>("");
    // const fileInputRef = useRef(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [successMsg, setSuccessMsg] = useState<any>(false);

    const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        // resetForm,
        setFieldValue

    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            console.log(values)
            var subject = "IMP: New Enquiry on Website";
            var apiKey = "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";

            var data = {
                sender: { email: values.email },
                to: [
                    { email: "prashanth@whistledrive.com" },
                    { email: "hello@whistledrive.com" },
                    { email: "mukesh.kondal@whistledrive.com" },
                    { email: "raju.mudireddy@whistledrive.com" },
                    { email: "vinay.yarabolu@whistledrive.com" },
                    { email: "sampath.vedantam@whistledrive.com" },
                ],
                // cc:
                subject: subject,
                templateId: 1,
                attachment: [
                    {
                        name: fileName,
                        content: values.file
                    }
                ],
                params: {
                    name: values.name,
                    email: values.email,
                    phone: values.mobile,
                    message: values.message
                }
            };
            const config: any = {
                method: "POST",
                url: 'https://api.sendinblue.com/v3/smtp/email',
                headers: {
                    "Content-Type": "application/json",
                    "api-key": apiKey
                },
                data: JSON.stringify(data)

            }

            axios(config)
                .then((res) => {
                    console.log("email sent suceessfully", res)
                    setSuccessMsg(true)
                })
                .catch((err) => {
                    console.log("err", err)
                })

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

            // setFileName("")
            resetForm()

        }
    })

    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>About us | Meet the Team- WhistleDrive</title>
                <meta name="description"
                    content="Learn more about who WhistleDrive is and what we do. Find all of the WhistleDrive company information, Mission, vision and meet the people who we work with." />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <img src='../assests/images/AboutUs/GroupImage.png' className={styles.groupImg} alt='group-img' />
                    <div className={styles.urbanContent}>
                        <h2>Reimagining how urban cities move</h2>
                    </div>
                    <div className={styles.webelieveCotnainer}>
                        <h2>We Believe In</h2>
                        <div className={styles.believeCardContent}>
                            <div className={styles.believeCard}>
                                <img src='../assests/images/AboutUs/Principles.png' alt='principles' />
                                <h5>First Principles Thinking</h5>
                            </div>
                            <div className={styles.believeCard}>
                                <img src='../assests/images/AboutUs/Solving.png' alt='solving' />
                                <h5>Solving Problems at Scale</h5>
                            </div>
                            <div className={styles.believeCard}>
                                <img src='../assests/images/AboutUs/BuildingFuture.png' alt='Future' />
                                <h5>Building for the Future</h5>
                            </div>

                        </div>


                    </div>
                    <div className={styles.teamHeading}>
                        <h2>Meet our team</h2>
                    </div>
                    <ul className={styles.teamMembersContainer}>
                        {TeamDetails.map(eachItem => <TeamCard key={eachItem.id} cardDetails={eachItem} />)}
                    </ul>
                    <div className={styles.explorerContainer}>
                        <h2>Explorers wanted!</h2>
                    </div>
                    <div className={styles.formContainer}>
                        <form className={styles.explorerFormContainer} onSubmit={handleSubmit}>
                            <div className={styles.inputContainer}>
                                <label htmlFor='FullName'>Full Name*</label>
                                <input
                                    type='text'
                                    className={styles.input}
                                    id='FullName'
                                    onChange={handleChange('name')}
                                    onBlur={handleBlur('name')}
                                    value={values.name}

                                />
                                {touched.name && errors.name ? (
                                    <p className={styles.errorMsg}>Name is required</p>
                                ) : null}


                            </div>
                            <div className={styles.inputContainer}>
                                <label htmlFor='Phone'>Phone Number*</label>
                                <input
                                    type='tel'
                                    className={styles.input}
                                    id='Phone'
                                    onChange={(text: any) => {
                                        const regex =
                                            /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                        const textValue = text.target.value
                                        if (regex.test(textValue.toString()) || textValue === "") {
                                            setFieldValue("mobile", textValue);
                                        }
                                        console.log(textValue)
                                        console.log(values.mobile)
                                    }}
                                    maxLength={10}
                                    onBlur={handleBlur('mobile')}
                                    value={values.mobile}
                                />
                                {touched.mobile && errors.mobile ? (
                                    <p className={styles.errorMsg}>Mobile number is required</p>
                                ) : null}

                            </div>
                            <div className={styles.inputContainer}>
                                <label htmlFor='email'>Email*</label>
                                <input
                                    type='email'
                                    className={styles.input}
                                    id='email'
                                    onChange={handleChange("email")}
                                    onBlur={handleBlur("email")}
                                    value={values.email}
                                />
                                {touched.email && errors.email ? (
                                    <p className={styles.errorMsg}>Email is required</p>
                                ) : null}

                            </div>
                            <div className={styles.inputContainer}>
                                <label htmlFor='file'>Attachment<span> <i className="fa fa-paperclip"></i></span></label>
                                <input
                                    type='file'
                                    className={styles.input}
                                    id='file'
                                    ref={fileInputRef}
                                    onChange={(event) => {
                                        if (event.target.files && event.target.files[0]) {
                                            console.log(event.target.files[0], "files")
                                            setFileName(event.target.files[0].name)
                                            // setFileName(event.target.files.FileList)
                                            const data = new FileReader()
                                            data.addEventListener('load', () => {
                                                const result = data.result
                                                if (typeof result === 'string') {
                                                    const base64String = result.replace("data:", "").replace(/^.+,/, "");
                                                    console.log(base64String, "result");
                                                    setFieldValue('file', base64String);
                                                }
                                                else {
                                                    alert("Please try again to upload")
                                                }
                                                // console.log(data.result,"result")
                                                // setFieldValue('file', data.result)
                                            })
                                            if (event.target.files && event.target.files.length > 0) {
                                                // Check if event.target.files is not null and has at least one file
                                                data.readAsDataURL(event.target.files[0]);
                                            }
                                        }


                                    }}

                                // value={fileName}
                                />
                                {touched.file && errors.file ? (
                                    <p className={styles.errorMsg}>File is required</p>
                                ) : null}

                            </div>
                            <div className={styles.inputContainer} style={{ width: '100%' }}>
                                <label htmlFor='message'>Message*</label>
                                <textarea
                                    cols={8}
                                    rows={8}
                                    className={styles.input}
                                    id='message'
                                    onChange={handleChange("message")}
                                    onBlur={handleBlur("message")}
                                    value={values.message}
                                />
                                {touched.message && errors.message ? (
                                    <p className={styles.errorMsg}>Message is required</p>
                                ) : null}

                            </div>

                            {successMsg ? <p style={{ color: 'green' }}>Thanks for interest</p> : ""}
                            <div className={styles.btnContainer}>
                                <button type='submit' className={styles.btn} onSubmit={() => handleSubmit()}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default AboutUs
