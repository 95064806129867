import React from 'react'
import styles from './OsForLogistics.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';
// import CountUp from 'react-countup';
// import ScrollTrigger from 'react-scroll-trigger';
import { useState } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import { Helmet } from 'react-helmet-async';


const logos = [
    { name: 'whistledrive-invesco-logo', id: 1 },
    { name: 'whistledrive-novartis-logo', id: 2 },
    { name: 'whistledrive-one-plus-logo', id: 3 },
    { name: 'whistledrive-examity-logo', id: 4 },
    { name: 'whistledrive-L&T-logo', id: 5 },
    { name: 'whistledrive-ADP-logo', id: 6 },
]

const drivingContent = [
    { text: 'eCommerce', id: 1 },
    { text: 'QCommerce', id: 2 },
    { text: ' D2C', id: 3 },
    { text: '3PLs', id: 4 },
    { text: 'FMCG & Retail', id: 5 },
    { text: 'Commodities', id: 6 },
]

const osContent = [
    { text: 'Deliveries Using Electric Vehicles', id: 1 },
    { text: 'Last-Mile Delivery Services', id: 2 },
    { text: 'Warehouse Management System Integration', id: 3 },
    { text: 'IoT Device Integration', id: 4 },
    { text: 'Demand & Supply Mapping Algorithm', id: 5 },
]

function OsForLogistics() {
    const [controlImg, setControlImg] = useState<any>("OrderManagement");
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>LogisticsOS | Logistics Management Software</title>
                <meta
                    name="description"
                    content="Helping enterprises digitize their logistics operations across all the delivery models like first-mile, middle-mile and last-mile operations."
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.subContent}>
                            <h5><span>WhistleOS</span> for Goods Transportation</h5>
                            <h2 className={styles.fullStack}> An Operating System that powers your transportation operations</h2>
                            <p className={styles.paragraph}> Built with core expertise across industries, our tech platform aims for maximum efficiency and helps businesses with faster delivery times at lower costs.</p>
                            <Link to="/contact-us">Contact Us</Link>
                        </div>
                        <div className={styles.mainImgContainer}>
                            <img src='../assests/images/OsForTransportation/OsForGoods.png' alt='os-for-goods' />
                        </div>

                        {/* <div className={styles.videoContainer}>
                            <video autoPlay loop muted style={{ width: '100%' }}>
                                <source src="../assests/images/home-page gif.mp4" type="video/mp4" />
                            </video>
                        </div> */}

                    </div>
                    <div className={styles.brandContainer}>
                        <div className={styles.brandImgsContainer}>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt='brand-logos' />
                                ))}
                            </div>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt='brand-logos'/>
                                ))}
                            </div>

                        </div>
                    </div>
                    <hr className={styles.line1} />
                    <div className={styles.oneOsContainer}>
                        <div className={styles.onOsContent}>
                            <h4>One OS. Many Industries</h4>
                            <p className={styles.paragraph}>
                                Built to Scale. Our tech platform can be put to use for transportation use-cases across a variety of
                                businesses,
                                no matter the size.
                            </p>
                            <ul className={styles.Contentlist}>
                                {drivingContent.map((eachItem: any) => {
                                    return (
                                        <li key={eachItem.id}>
                                            <span><i className={`fa fa-check ${styles.tickIcon}`}></i></span>
                                            {eachItem.text}
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>
                        <div className={styles.imgContainer}>
                            <img src='../assests/images/whistle-os-img.png' alt='whistle-os' />
                        </div>
                    </div>
                    <div className={styles.fullControlContainer}>
                        <div className={styles.fullControlContent}>
                            <h2>Technology as an Integral part of the Supply Chain Process</h2>
                            <p className={styles.paragraph}>Personalized Tech Platform for First Mile, Mid Mile and Last Mile Operations.</p>
                            <div
                                className={styles.hover}
                                style={controlImg === "OrderManagement" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={() => setControlImg("OrderManagement")}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${controlImg === "OrderManagement" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Order Management
                                </h3>
                                {controlImg === "OrderManagement" && (<p className={styles.para}>
                                    Manage your orders across all the leading eCommerce, Social Commerce, QCommerce and custom app or website channels using a centralized dashboard.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={controlImg === "Routeoptimization" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={() => setControlImg("Routeoptimization")}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${controlImg === "Routeoptimization" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Route optimization
                                </h3>
                                {controlImg === "Routeoptimization" && (<p className={styles.para}>
                                    Our route planning algorithm eliminates the manual effort in daily decision-making and helps to drive the operations efficiently.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={controlImg === "Livetracking" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={() => setControlImg("Livetracking")}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${controlImg === "Livetracking" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Live tracking
                                </h3>
                                {controlImg === "Livetracking" && (<p className={styles.para}>
                                    Track all the vehicles in real-time using our dashboard or mobile app, also receive all the important alerts.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={controlImg === "Reports" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={() => setControlImg("Reports")}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${controlImg === "Reports" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Reports and billing
                                </h3>
                                {controlImg === "Reports" && (<p className={styles.para}>
                                    Automated reports and invoices to make key decisions.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={controlImg === "E-tripsheet" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={() => setControlImg("E-tripsheet")}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${controlImg === "E-tripsheet" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    E-trip sheet
                                </h3>
                                {controlImg === "E-tripsheet" && (<p className={styles.para}>
                                    Our e-trip sheets eliminate the use of paper from the whole process, saving costs while maintaining transparency.
                                </p>)}

                            </div>
                        </div>
                        <div className={styles.fullControlImgContainer}>
                            <img src={`../assests/images/OsForTransportation/${controlImg}.png`}  alt='os-for-transportaion'/>

                        </div>


                    </div>
                    <div className={styles.operatingSystemContainer}>
                        <div className={styles.operatingSystemContent}>
                            <h4>An Operating System that can be Integrated Seamlessly!</h4>
                            <p>
                                An intelligent and adaptable mobility platform
                                that transforms the way enterprises manage their transportation ops.
                            </p>
                            <ul className={styles.Contentlist}>
                                {osContent.map((eachItem: any) => {
                                    return (
                                        <li key={eachItem.id}>
                                            <span><i className={`fa fa-check ${styles.tickIcon}`}></i></span>
                                            {eachItem.text}
                                        </li>
                                    )
                                })}

                            </ul>

                        </div>
                        <div className={styles.osImgContainer}>
                            <img src='../assests/images/OsForTransportation/whistle-os.png'  alt='os-for-transportation'/>

                        </div>


                    </div>
                    <ContactUsCard img={"ET-Contact"} text={"Looking for an advanced mobility platform for Intracity deliveries?"} />
                </div>
                <Footer />
            </div>

        </div>
    )
}

export default OsForLogistics
