import React from 'react'
import styles from './TermsAndConditions.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async'

function TermsAndConditions() {
    return (
        <div className={styles.mainContainer}>
            <Navbar1 />
            <Helmet>
                <title>Terms & Conditions - WhistleDrive</title>
                <meta
                    name="description"
                    content="These Standard Terms and Conditions on Whistledrive.com shall apply to all deliveries and other services provided by WhistleDrive Pvt. Ltd. "
                />
            </Helmet>
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.termsHeading}>
                        <h2>Terms of Use</h2>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Welcome to our mobile application and / or our website.</h5>
                        <p>
                            These terms and conditions of use (‘’Terms of Use’’) of the
                            Whistledrive website and mobile app, is a legal agreement
                            (“Agreement”) between Whistledrive services Pvt. Ltd.
                            (“Whistledrive”), a partnership firm registered under the laws of
                            India having its offices at CDC Towers, Plot No.10/8, Road No.5,
                            IDA Nacharam, Hyderabad – 500076, Telangana and
                            yourself/yourselves. If you continue to browse the website and/or
                            use the mobile application you are agreeing to comply with and be
                            bound by the Terms of Use.
                        </p>
                        <p>
                            If you do not agree with the Terms of Use, please do not access
                            and use this Website / Application (as hereinafter defined).
                        </p>

                    </div>
                    <div className={styles.termsContent}>
                        <h5>Definitions:</h5>
                        <p>For the purposes of the Terms of Use and Privacy Policy:</p>
                        <p>“Whistledrive”, “we”, “our” and “us” shall mean Whistledrive;</p>
                        <p>
                            “You”, yourself / yourselves and “your” shall mean a User, who
                            meets the eligibility criteria set out below;
                        </p>
                        <p>
                            “Application” shall mean the application supplied by Whistledrive
                            and downloaded and installed by you on your single mobile device
                            (smart phone);
                        </p>
                        <p>
                            “Driver” or ‘’Drivers’’ shall mean the driver engaged by you by
                            using our Website/Application;
                        </p>
                        <p>
                            “Force Majeure” shall have the meaning as set out in Clause VII;
                        </p>
                        <p>
                            Service” or “Services” shall mean the service of providing
                            short-term private Driver solutions via the Application/Website as
                            defined in Clause I;
                        </p>
                        <p>
                            ‘’User’’ or ‘’Users’’ shall have the meaning as set out in Clause
                            III; and ‘’Website’’ shall mean the website www.Whistledrive.Com
                        </p>
                        <p>
                            These Terms of Use may be modified from time to time in our sole
                            discretion. It is your responsibility to review these Terms and
                            Conditions from time to time. If you continue to use the Service
                            after notice of change has been intimated or published on our
                            Website/Application, you thereby provide your consent to the
                            changed practices on the same terms hereof. For this reason, we
                            encourage you to review these Terms of Use each time you access
                            and use the Website/Application. Most content and some of the
                            features on the Website/Application are made available to Users
                            free of charge. However, Whistledrive reserves the right to
                            terminate access to certain areas or features of the
                            Website/Application at any time for any reason, with or without
                            notice.
                        </p>
                        <p>
                            If you are not agreeable to these Terms of Use, you may not use
                            the Services, the Website or the Application.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>I. Services descrption:</h5>
                        <p>
                            Whistledrive provides a technology based service which enables the
                            hiring of drivers by commuters/ passengers for a point to point
                            pick up and drop off Service, within city limits and outside city
                            limits, through the Application in mobile telecommunications
                            devices and through the Website. The Service is designed to offer
                            you information and a means of obtaining short-term private driver
                            solutions to coordinate point-to-point and round trip private
                            driver services, at your request. As a User, you authorize
                            Whistledrive to operate your vehicle and make decisions on your
                            behalf during a period of time designated by you.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>II. Use of service, application and website:</h5>
                        <ul>
                            <li>
                                The Application and the Website allows you to send a request for
                                Service to a Driver. The GPS receiver, which should be installed
                                on the mobile device (smart phone) on which you have downloaded
                                the Application or the Website, shall detect your location and
                                shall send your location information to the relevant Driver.
                                Whistledrive has sole and complete discretion to accept or
                                reject each request for providing the Service. Whistledrive also
                                has sole and complete discretion over whether to use the
                                Application / Website to receive the leads generated through the
                                Application / Website. If Whistledrive accepts a request, the
                                Application / Website notify you and provide information
                                regarding the Driver – including his name and the ability to
                                contact the Driver by telephone or a message. The Application /
                                Website also allow you to view the Driver’s progress towards the
                                pick-up point, in real time.
                            </li>
                            <li>
                                Whistledrive shall undertake commercially reasonable efforts to
                                bring you into contact with a Driver in order to avail the
                                Services, subject to the availability of Drivers in or around
                                your location at the moment of your request for the Services.
                            </li>
                            <li>
                                Whistledrive itself does not provide transportation services. It
                                is up to the User to offer transportation. Whistledrive only
                                acts as intermediary between the Driver and you. The provision
                                of the Services by the Driver to you is, therefore, subject to
                                the agreement (to be) entered into between the Driver and you.
                                Whistledrive shall under no circumstance be a party to such
                                agreement. Whistledrive disclaims any and all liability in
                                respect of the Drivers including any claims of employment or any
                                vicarious liability arising out of the Service or otherwise.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h4>Use of service, application and website:</h4>
                        <p>
                            User means any individual or business entity/organization that
                            legally operates in India or in other countries, and uses and has
                            the right to use the Services provided by Whistledrive. Our
                            Services are available only to those individuals or entities who
                            can execute legally binding contracts under the applicable law.
                            Therefore, a User must not be a minor as per Indian Law; i.e.
                            User(s) must be at least 18 years of age to be eligible to use our
                            Services. Whistledrive advises its Users that while accessing the
                            Website/Application, they must follow/abide by the applicable
                            laws. Whistledrive may, in its sole discretion, refuse the Service
                            to anyone at any time.
                        </p>
                        <p>
                            This Agreement applies to all Services offered on the
                            Website/Application, collectively with any additional terms and
                            condition that may be applicable.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Registration</h5>
                        <p>
                            To use the Services, you have to be registered and provide your
                            name, contact number, email address and other details. Please see
                            our Privacy Policy and practices to know more about how your
                            personal information would be used.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Representation and Warranties</h5>
                        <p>
                            As a precondition to your use of the Services, you represent and
                            warrant that:
                        </p>
                        <ul>
                            <li>
                                The information you provide to Whistledrive is accurate and
                                complete. Whistledrive service is only available for private
                                cars for non-commercial purposes within the city limits as
                                designated on our Website. Private cars for non-commercial
                                purposes bear license plates with black lettering over white
                                colored background (Commercial vehicles have license plates with
                                black lettering over yellow colored background). You will ensure
                                that Whistledrive service is being utilized only for
                                non-commercial purposes in a private car. Whistledrive is
                                entitled, at all times, to verify the information that you have
                                provided and to refuse the Service or use of the Application /
                                Website without providing reasons.
                            </li>
                            <li>
                                You will only access the Service using authorized means. You are
                                responsible to check and ensure you download the correct
                                Application for your device or the correct Website in your
                                computer. Whistledrive shall not be liable if you do not have a
                                compatible mobile device or if you download the wrong version of
                                the Application for your mobile device or Website for the
                                computer. Whistledrive reserves the right to terminate the
                                Service and the use of the Application/ Website should you use
                                the Service or Application with an incompatible or unauthorized
                                device.
                            </li>
                            <li>
                                You have the legal right and authority to possess and operate
                                the vehicle when engaging our Services and you confirm,
                                represent and warrant that the said vehicle is in good operating
                                condition and meets the industry safety standards and all
                                applicable statutory requirements for a motor vehicle of its
                                kind.
                            </li>
                            <li>
                                You will be solely responsible for any and all liability which
                                results from or is alleged as a result of the condition of your
                                vehicle, legal compliance, etc., including, but not limited to,
                                personal injuries, Vehicle damage, and death and property
                                damages.
                            </li>
                            <li>
                                You will be solely responsible for the full functionality of
                                your vehicle. If your vehicle fails to function (electrical,
                                mechanical or other) in any way while the Services are being
                                availed of by you, you will be responsible for all storage fees,
                                roadside assistance, alternate transportation and repair of any
                                kind and neither Whistledrive nor the Driver shall be
                                responsible in any manner whatsoever. You have the legal right
                                to designate the Driver as your agent and delegate actual
                                authority to the Driver to operate your vehicle and make
                                decisions on your behalf for the purposes of providing Services
                                offered through the Whistledrive platform.
                            </li>
                            <li>
                                You are named or scheduled on the insurance policy covering the
                                vehicle you use when engaging our Services.
                            </li>
                            <li>
                                You have a valid policy of liability insurance (in coverage
                                amounts consistent with all applicable legal requirements) for
                                the operation of your vehicle to cover any anticipated losses
                                related to your participation in the Services or the operation
                                of your vehicle by the Driver. In the event of a motor vehicle
                                accident you will be solely responsible for compliance with any
                                applicable statutory or department of motor vehicles
                                requirements and for all necessary contacts with your insurance
                                provider. Other than any personal criminal liability attaching
                                to the Driver you will be solely responsible for all
                                consequences arising out of the use of the Service or the
                                Driver. In any event Whistledrive shall have no responsibility
                                or liability on this account whatsoever.
                            </li>
                            <li>
                                You specifically authorize us to use, store or otherwise process
                                your ‘Sensitive personal data or information’ (as such term is
                                defined in Information Technology (Reasonable security practices
                                and procedures and sensitive personal data or information)
                                Rules, 2011) in order to provide the Services to you. Subject to
                                applicable law all information provided to us by you shall be
                                deemed to be our information to use as we desire.
                            </li>
                            <li>
                                You will obey all applicable laws related to the matters set
                                forth herein, and will be solely responsible for any violations
                                of the same.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Meter start and cancellation policy</h5>
                        <ul>
                            <li>
                                Whistledrive drivers will wait no more than 20 minutes from the
                                requested trip start time, before they start the trip timer.
                            </li>
                            <li>
                                You may cancel the booking within 30 minutes prior to the time
                                of journey, without any cancellation charges for all Services.
                                The customer is liable for Rs 100/- charge if the trip is
                                cancelled thereafter.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Meter start and cancellation policy</h5>
                        <ul>
                            <li>
                                Whistledrive drivers will wait no more than 20 minutes from the
                                requested trip start time, before they start the trip timer.
                            </li>
                            <li>
                                You may cancel the booking within 30 minutes prior to the time
                                of journey, without any cancellation charges for all Services.
                                The customer is liable for Rs 100/- charge if the trip is
                                cancelled thereafter.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Limitation of liability</h5>
                        <ul>
                            <li>
                                The information, recommendations and/or Services provided to you
                                on or through the Website/Application are for general
                                information purposes only and do not constitute advice.
                                Whistledrive will take reasonable steps to keep the
                                Website/Application and its contents correct and up to date but
                                does not guarantee that the contents of the Website/Application
                                are free of errors, defects, malware and viruses or that the
                                Website/Application are correct, up to date and accurate.
                            </li>
                            <li>
                                Whistledrive shall not be liable for any damages resulting from
                                the use of, or inability to use, the Website/Application,
                                including damages caused by malware, viruses or any
                                incorrectness or incompleteness of the information on the
                                Website/Application.
                            </li>
                            <li>
                                Whistledrive shall further not be liable for damages resulting
                                from the use of, or the inability to use, electronic means of
                                communication with the Website/Application, including — but not
                                limited to — damages resulting from failure or delay in delivery
                                of electronic communications, interception or manipulation of
                                electronic communications by third parties or by computer
                                programs used for electronic communications and transmission of
                                viruses.
                            </li>
                            <li>
                                Without prejudice to the foregoing, and insofar as allowed under
                                mandatory applicable law, Whistledrive’s aggregate liability
                                shall in no event exceed the equivalent of the amount for the
                                payment of the Services.
                            </li>
                            <li>
                                The quality of the Services requested through the use of the
                                Application is entirely the responsibility of the Driver who
                                ultimately provides such transportation services to you.
                                Whistledrive under no circumstance accepts liability in
                                connection with and/or arising from the Services provided by the
                                Driver or any acts, actions, behavior, conduct, and/or
                                negligence on the part of the Driver.
                            </li>
                            <li>
                                We shall not be held liable for any failure or delay in
                                performing Services where such failure arises as a result of any
                                act or omission, which is outside our reasonable control such as
                                unprecedented circumstances, overwhelming and unpreventable
                                events caused directly and exclusively by forces of nature that
                                can be neither anticipated, nor controlled, nor prevented by the
                                exercise of prudence, diligence, and care, including but not
                                limited to: war, riot, civil commotion; compliance with any law
                                or governmental order, rule, regulation or direction and acts of
                                third parties ( “Force Majeure” ).
                            </li>
                            <li>
                                If we have contracted to provide identical or similar Service to
                                more than one User and are prevented from fully meeting our
                                obligations to you by reason of an event of Force Majeure, we
                                may decide at our absolute discretion which booking we will
                                fulfill by providing the Service, and to what extent.
                            </li>
                            <li>
                                We have taken all reasonable steps to prevent internet fraud and
                                ensure any data collected from you is stored as securely and
                                safely as possible. However, we shall not be held liable in the
                                unlikely event of a breach in our secure computer servers or
                                those of third parties other than as required under applicable
                                law.
                            </li>
                            <li>
                                In the event we have a reasonable belief that there exists an
                                abuse of vouchers and/or discount codes or suspect an instance
                                of fraud, we may cause the User to be blocked immediately and
                                reserve the right to refuse future Service. Additionally, should
                                there exist an abuse of vouchers or discount codes, Whistledrive
                                reserves the right to seek compensation from any and all such
                                Users.
                            </li>
                            <li>
                                Whistledrive does not represent or endorse the accuracy or
                                reliability of any information, or advertisements (collectively,
                                the “Content”) contained on, distributed through, or linked,
                                downloaded or accessed from or contained on the
                                Website/Application, or the quality of any products, information
                                or other materials displayed, or obtained by you as a result of
                                an advertisement or any other information or offer in or in
                                connection with the Service.
                            </li>
                            <li>
                                Offers are subject to Whistledrive’s discretion and may be
                                withdrawn at any time and without notice.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Intellectual property rights</h5>
                        <ul>
                            <li>
                                Whistledrive is the sole owner or lawful licensee of all the
                                rights to the Website/Application and its content.
                                Website/Application content means its design, layout, text,
                                images, graphics, sound, video etc. The Website/Application
                                content embodies trade secrets and intellectual property rights
                                protected under worldwide copyright and other laws. All title,
                                ownership and intellectual property rights in the
                                Website/Application and its content shall remain with
                                Whistledrive.
                            </li>
                            <li>
                                All rights not otherwise claimed under this Agreement or in the
                                Website /Application, are hereby reserved. The information
                                contained in this Website/Application is intended, solely to
                                provide general information for the personal use of the reader,
                                who accepts full responsibility for its use.
                            </li>
                            <li>
                                You may access the Website/Application, avail of the features,
                                facilities and Services for your personal or internal
                                requirements only. You are not entitled to duplicate,
                                distribute, create derivative works of, display, or commercially
                                exploit the Website/Application Content, features or facilities,
                                directly or indirectly, without our prior written permission of
                                Whistledrive.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Copyright</h5>
                        <ul>
                            <li>
                                All content on this Website/Application is the copyright of
                                Whistledrive except the third party content and link to third
                                party website on our Website/Application, if any.
                            </li>
                            <li>
                                Systematic retrieval of Whistledrive content to create or
                                compile, directly or indirectly, a collection, compilation,
                                database or directory (whether through robots, spiders,
                                automatic devices or manual processes) without written
                                permission from Whistledrive is prohibited.
                            </li>
                            <li>
                                In addition, use of the content for any purpose not expressly
                                permitted in this Terms of Use is prohibited and may invite
                                legal action. As a condition of your access to and use of
                                Services, you agree that you will not use the
                                Website/Application to infringe the intellectual property rights
                                of others in any way. Whistledrive reserves the right to
                                terminate the account of a User upon any infringement of the
                                rights of others in conjunction with use of the Service, or if
                                Whistledrive believes that User’s conduct is harmful to the
                                interests of Whistledrive, its affiliates, or other Users, or
                                for any other reason in Whistledrive’s sole discretion, with or
                                without cause.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>User accounts, offers and promotions</h5>

                        <ul>
                            <li>
                                Whistledrive reserves the right to collect User data including
                                name, contact information and other details to facilitate
                                Services or use of its platform to avail Services. All
                                information collected from the Users are on a bona fide basis.
                                Misuse and misrepresentation of identity or contact details will
                                lead to automatic termination of Services or the use of the
                                platform, without prior notice to such Users.
                            </li>
                            <li>
                                User accounts bearing contact number and email IDs are created
                                and owned by Whistledrive. Any promotional discounts and offers
                                accumulated can be revoked without prior notice in the event of
                                suspicious account activity or mala fide intent of the User.
                            </li>
                            <li>
                                In the case where the system is unable to establish unique
                                identity of a User against a valid mobile number or e-mail ID,
                                the account shall be indefinitely suspended. Whistledrive
                                reserves the full discretion to suspend a User’s account in the
                                above event and does not have the liability to share any account
                                information whatsoever.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Links to third party sites</h5>
                        <ul>
                            <li>Links to third party sites</li>
                            <li>
                                Whistledrive may allow Users access to content, products or
                                services offered by third parties through hyperlinks (in the
                                form of word link, banners, channels or otherwise) to such third
                                party’s website. You are cautioned to read such sites’ terms and
                                conditions and/or privacy policies before using such sites in
                                order to be aware of the terms and conditions of your use of
                                such sites. The Users acknowledge that Whistledrive has no
                                control over such third party’s site, does not monitor such
                                sites, and Whistledrive shall not be responsible or liable to
                                anyone for such third party site, or any content, products or
                                services made available on such a site.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Termination</h5>
                        <ul>
                            <li>
                                Whistledrive reserves the right to deny access to particular
                                Users to any/all of its Services without any prior
                                notice/explanation in order to protect the interests of
                                Whistledrive and/or other Users to the Website/Application.
                                Whistledrive reserves the right to limit, deny or create
                                different access to the Website/Application and its features
                                with respect to different Users.
                            </li>
                            <li>
                                We reserve the right to terminate your account or your access to
                                the Website/Application immediately, with or without notice to
                                you, and without liability: (i) if you have violated any of the
                                Terms of Use; (ii) if you have furnished us with false or
                                misleading information; (iii) pursuant to requests by law
                                enforcement or other government agencies; (iv) in case of
                                unexpected technical or security issues or problems; (v) in case
                                of discontinuance or material modification to the Services (or
                                any part thereof); and / or (vi) in case of interference with
                                use of our Website/Application by others.
                            </li>
                            <li>
                                In the event of termination by you or us, your account will be
                                disabled and you will not be granted access to your account or
                                any information or content contained in your account. You will
                                not and not attempt to create another account for accessing and
                                using the Website/Application without the written consent of
                                Whistledrive.
                            </li>

                            <li>
                                This Terms of Use shall remain in full force and effect while
                                you have an account with us. Even after termination of your
                                account with us, certain provisions of this Terms of Use will
                                remain in effect, including but not limited to, Intellectual
                                Property Rights, Prohibited Uses and Indemnification. You agree
                                that we will not be liable to you or any third party for taking
                                any of these actions.
                            </li>
                            <li>
                                Notwithstanding the termination of this Agreement, you shall
                                continue to be bound by the terms of this Agreement in respect
                                of your prior use of this Website/Application and all matters
                                connected with, relating to or arising from such use.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Terms & Conditions for use of Services</h5>
                        <p>
                            The following terms & conditions shall apply to Users utilising
                            the Services offered by Whistledrive:
                        </p>
                        <ul>
                            <li>
                                The User shall pay the driver fare (as displayed in the
                                Whistledrive App) or as agreed to in the terms of use as listed
                                on the Application / Website, parking charges, additional night
                                surcharge (where applicable), one way trips, toll charges and
                                any fee or levy presently payable or hereinafter imposed by the
                                law or required to be paid for availing of the Services.
                            </li>
                            <li>
                                The User agrees and accepts that the use of the Services
                                provided by Whistledrive is at the sole risk of the User, and
                                further acknowledges that Whistledrive disclaims all
                                representations and warranties of any kind, whether express or
                                implied. All Services are provided “AS IS”.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h4>
                            The Whistledrive or the Driver has the right to refuse the Service
                            in the following circumstances:
                        </h4>
                        <ol>
                            <li>
                                if the User is found to be in an intoxicated state or is found
                                misbehaving with other passengers or the Driver, or is causing a
                                nuisance;
                            </li>
                            <li>
                                if the User is misusing, soiling or damaging any of the devices
                                (technical/non-technical) of the Driver; and
                            </li>
                            <li>
                                if the User asks the Driver to break any traffic/RTO/city police
                                and/or government rules for any purpose The Driver has the right
                                to refuse such a request by the User.
                            </li>
                        </ol>
                    </div>
                    <div className={styles.termsContent}>
                        <h4>
                            Without prejudice to the above, Whistledrive makes no
                            representation or warranty that:
                        </h4>
                        <ol>
                            <li>the Services will meet the User’s requirements; and</li>
                            <li>
                                the Services will be uninterrupted, timely, secure, or
                                error-free.
                            </li>
                            <li>
                                The information on this Website/Application is provided “AS IS”
                                with no guarantee of completeness, accuracy, timeliness or of
                                the results obtained from the use of this information, and
                                without warranty of any kind, express or implied, including, but
                                not limited to warranties of performance, merchantability and
                                fitness for a particular purpose. Nothing herein shall to any
                                extent substitute for the independent investigations and the
                                sound technical and business judgment of the Users. In no event
                                shall Whistledrive be liable for any direct, indirect,
                                incidental, punitive, or consequential damages of any kind
                                whatsoever with respect to the Service. Users of this site must
                                hereby acknowledge that any reliance upon any content shall be
                                at their sole risk.
                            </li>
                            <li>
                                If any User misses his/her train or flight or bus, Whistledrive
                                will not be liable for any compensation.
                            </li>
                            <li>
                                Whistledrive is hereby authorized to use the location based
                                information provided by any of the telecommunication companies
                                when the User uses the mobile phone to make a booking. The
                                location based information will be used only to facilitate and
                                improve the probability of locating a Driver for the User.
                            </li>
                            <li>
                                Whistledrive shall be entitled to disclose to any government
                                body the particulars of the User in the possession, in its
                                absolute discretion.
                            </li>
                            <li>
                                Whistledrive shall be entitled at any time without giving any
                                reason to terminate the booking of Drivers done by the User.
                            </li>
                            <li>
                                If the User has any complaints in respect of the Services or the
                                use of the Driver, the User has to inform Whistledrive of the
                                same in writing within 24 hours of using the Driver or the
                                Services.
                            </li>
                            <li>
                                Whistledrive shall not be liable for any conduct of the Drivers.
                                However, Whistledrive encourages you to notify it of any
                                complaints that you may have against any Driver or the Services.
                            </li>
                            <li>
                                Whistledrive shall not responsible for any accidents and
                                damages, customer need to have insurance and all related
                                documents in non-expire condition.
                            </li>
                            <li>
                                Whistledrive shall not responsible for any thefts while using
                                our services. we request to customers to take care of their
                                personal things.
                            </li>
                        </ol>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Insurance and damage to property</h5>
                        <ol>
                            <li>
                                We do not procure insurance for, nor are we responsible for,
                                damage or loss of any personal belongings or intellectual
                                property whilst providing the Services.
                            </li>
                        </ol>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Indemnity</h5>
                        <p>
                            The User shall defend, indemnify and hold, Whistledrive, its
                            affiliates, its licensors, and each of their officers, directors,
                            other users, employees, attorneys and agents, harmless, from and
                            against any and all claims, costs, damages, losses, liabilities
                            and expenses (including attorneys’ fees and costs) arising out of
                            or in connection with the:
                        </p>
                        <ul>
                            <li>
                                violation or breach of the Terms of Use or any applicable law or
                                regulation, whether or not referenced herein;
                            </li>
                            <li>
                                violation of any rights of any third party, including the Driver
                                via the Application and or the Website; and
                            </li>
                            <li>Use or misuse of the Application/Website or Service.</li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Miscellaneous</h5>
                        <ul>
                            <li>
                                The information contained in this Website/Application is for
                                general information purposes only. The information is provided
                                by Whistledrive and while we endeavor to keep the information up
                                to date and correct, we make no representations or warranties of
                                any kind, express or implied, about the completeness, accuracy,
                                reliability, suitability or availability with respect to the
                                Website/Application or the information, products, services, or
                                related graphics contained on the Website/Application for any
                                purpose. Any reliance you place on such information is,
                                therefore, strictly at your own risk.
                            </li>
                            <li>
                                No event will we be liable for any loss or damage, including
                                without limitation, indirect or consequential loss or damage, or
                                any loss or damage whatsoever arising from loss of data or
                                profits arising out of, or in connection with, the use of this
                                Website/Application or Service.
                            </li>
                            <li>
                                Every effort is made to keep the Website/Application up and
                                running smoothly. However, Whistledrive takes no responsibility
                                for, and will not be liable for, the Website/Application being
                                unavailable due to technical issues beyond our control.
                            </li>
                            <li>
                                The above mentioned Terms of Use and the Privacy Policy
                                constitute the entire agreement between the User and
                                Whistledrive with respect to access to and use of the Website/
                                Application and the Services offered by Whistledrive,
                                superseding any prior written or oral agreements in relation to
                                the same subject matter herein.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Jurisdiction</h5>
                        <p>
                            The courts of Hyderabad/Secunderabad, India shall have the sole
                            and exclusive jurisdiction in respect of any matters arising from
                            the use of the Services offered by Whistledrive or the Terms of
                            Use or any arrangement between Whistledrive and the User.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h4>
                            Information that is or may be collected from you. We may collect
                            the following information:
                        </h4>
                        <ul>
                            <li>name and job title;</li>
                            <li>contact information including email address;</li>
                            <li>
                                demographic information such as postcode, preferences and
                                interests;
                            </li>
                            <li>information relevant to customer surveys and/or offers;</li>
                            <li>Geo-location;</li>
                            <li>
                                financial information (like account or credit card numbers);
                            </li>
                            <li>opinions of features on this Website/Application; and</li>
                            <li>Other information as per our registration process.</li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h4>We may also collect the following information:</h4>
                        <ul>
                            <li>about the pages you visit/access;</li>
                            <li>the links you click on this Website;</li>
                            <li>the number of times you access a particular page; and</li>
                            <li>the number of times you have interacted on this Website.</li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h4>What we do with the information we gather:</h4>
                        <p>
                            We require this information to understand your needs and provide
                            you with a better service, and in particular for the following
                            reasons:
                        </p>

                        <ul>
                            <li>Internal record keeping;</li>
                            <li>We may use the information to improve our Services;</li>
                            <li>
                                We may periodically send promotional emails about new products,
                                special offers or other information which we think you may find
                                interesting using the email address which you have provided; and
                            </li>
                            <li>
                                From time to time, we may also use your information to contact
                                you for market research purposes. We may contact you by email,
                                phone, fax or mail. We may use the information to customize the
                                Website/Application according to your interests.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Security</h5>
                        <p>
                            We are committed to ensuring that your information is secure. In
                            order to prevent unauthorized access or disclosure we have put in
                            place suitable physical, electronic and managerial procedures to
                            safeguard and secure the information we collect online.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Account security</h5>
                        <p>
                            You are solely responsible for the confidentiality of any password
                            and other account information that you create in order to access
                            or use the Website/ Application. You agree to notify us
                            immediately on any unauthorized use of your account, user name, or
                            password. You also agree that Whistledrive is not liable in any
                            manner for any loss that you may incur as a result of any third
                            party using your password, either with or without your knowledge.
                            You are solely liable for any losses incurred by us, our
                            affiliates, officers, directors, employees, consultants, agents,
                            and representatives due to misuse of your account or password. You
                            will not under any circumstances use the account, username, or
                            password of someone else at any time.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Prohibited users</h5>
                        <p>
                            You are prohibited from violating or attempting to violate any
                            security features of any of our Website/ Application, including,
                            without limitation: (a) accessing content or data not intended for
                            you, or logging onto a server or account that you are not
                            authorized to access; (b) attempting to probe, scan, or test the
                            vulnerability of any of our Website/ Application or any associated
                            services, system or network, or to breach security or
                            authentication measures without proper authorization; (c) using
                            any automated process or service (such as, by way of example only,
                            any spider, robot, or automated searching or “scraping” tool) to
                            monitor, access or copy any content from any Website/Application;
                            (d) interfering or attempting to interfere with service to any
                            user, host, or network, including, without limitation, by means of
                            submitting a virus to the Website/Application, overloading,
                            initiating or facilitating any “denial of service” attack,
                            “flooding,” “spamming,” “mail bombing,” or “crashing;” (e) using
                            any our Website/ Application or servers to send unsolicited
                            e-mail, including, without limitation, promotions, or
                            advertisements for products or services; (f) forging any TCP/IP
                            packet header or any part of the header information in any e-mail
                            or in any posting using any our Website/ Application; or (g)
                            attempting to modify, reverse-engineer, decompile, disassemble, or
                            otherwise reduce or attempt to reduce to a human-perceivable form
                            any of the source code used by Whistledrive in providing the
                            Website/ Application or our Services. Any violation of system or
                            network security may subject you to civil and/or criminal
                            liability, and will result in a loss of your ability to access and
                            use the Website/Application.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Debit/credit card details</h5>
                        <p>
                            You agree, understand and confirm that the credit card details
                            provided by you for registering as this Website/ Application will
                            be correct and accurate and you shall not use the credit card
                            which is not lawfully owned by you. You further agree and
                            undertake to provide the correct and valid credit card details to
                            us. Further, the said information will not be utilized and shared
                            by us with any of the third parties unless required by law,
                            regulation or court order.
                        </p>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>How we use cookies</h5>
                        <ul>
                            <li>
                                A cookie is a small file which asks permission to be placed on
                                your computer’s hard drive. Once you agree, the file is added
                                and the cookie helps analyze web traffic or lets you know when
                                you visit a particular site. Cookies allow web applications to
                                respond to you as an individual. The web application can tailor
                                its operations to your needs, likes and d
                            </li>
                            <li>
                                We use traffic log cookies to identify which pages are being
                                used. This helps us analyze data about pngage traffic and
                                improve our website in order to tailor it to customer needs. We
                                only use this information for statistical analysis purposes and
                                then the data is removed from the system.
                            </li>
                            <li>
                                Overall, cookies help us provide you with a better
                                Website/Application, by enabling us to monitor which pages you
                                find useful and which you do not. A cookie in no way gives us
                                access to your computer or any information about you, other than
                                the data you choose to share with us.
                            </li>
                            <li>
                                You can choose to accept or decline cookies. Most web browsers
                                automatically accept cookies, but you can usually modify your
                                browser setting to decline cookies if you prefer. This may
                                prevent you from taking full advantage of the website.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.termsContent}>
                        <h5>Links to other websites</h5>
                        <ul>
                            <li>
                                Our Application /Website may contain links to other websites of
                                interest. However, once you have used these links to leave our
                                site, you should note that we do not have any control over that
                                other website. Therefore, we cannot be responsible for the
                                protection and privacy of any information which you provide
                                whilst visiting such sites and such sites are not governed by
                                this privacy statement. You should exercise caution and look at
                                the privacy statement applicable to the website in question.
                            </li>
                            <li>
                                We will not sell, distribute or lease your personal information
                                to third parties unless we have your permission or are required
                                by law to do so. We may use your personal information to send
                                you promotional information about third parties which we think
                                you may find interesting if you tell us that you wish this to
                                happen.
                            </li>
                            <li>
                                Notwithstanding the above, we confirm that we shall at all times
                                be in full compliance with the Information Technology
                                (Reasonable security practices and procedures and sensitive
                                personal data or information) Rules, 2011.
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default TermsAndConditions

