import React from 'react'
import styles from './TechCard.module.css'
const redefiningContent = [
    { text: 'Smart Mobile Application', id: 1 },
    { text: 'Live Tracking', id: 2 },
    { text: 'Advanced Safety & Security', id: 3 },
    { text: 'Ad-hoc Request feature', id: 4 },


]


function TechCard({ content }: any) {
    return (

        <div className={styles.techCard}>
            <img src={`../assests/images/OsForMobility/${content.img}.png`} alt={content.img} />
            <h5>{content.heading}</h5>
            <ul className={styles.redefineContentlist}>
                {content.description.map((eachItem: any) => {
                    return (
                        <li key={eachItem.id}>
                            <span><i className={`fa fa-check ${styles.tickIcon}`}></i></span>
                            {eachItem.text}
                        </li>
                    )
                })}
            </ul>

        </div>

    )
}

export default TechCard
