import React, { useState, useEffect, useRef } from 'react';
import './AnimationText.css'; // Import your CSS styles

function AnimatedText() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const words = ['Connected', 'Integrated', 'Intelligent'];
  const animationDelay = 2500;

  const animateText = useRef<any>(null);

  useEffect(() => {
    animateText.current = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, animationDelay);

    return () => {
      // Clean up the timer when the component unmounts
      clearTimeout(animateText.current);
    };
  }, [currentIndex]);

  return (
    <h6 className="cd-headline rotate-1">
      <span className="cd-words-wrapper">
        {words.map((word, index) => (
          <b
            key={index}
            className={index === currentIndex ? 'is-visible' : 'is-hidden'}
          >
            {word}
          </b>
        ))}
      </span>
    </h6>
  );
}

export default AnimatedText;
