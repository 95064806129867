import React from 'react'
import styles from './QualityPolicy.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async'

function QualityPolicy() {
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Quality Policy - WhistleDrive</title>
                <meta
                    name="description"
                    content="Your privacy is important to you and to us. So we’ll protect the information you share with us. To protect your privacy, the Site follows different principles in accordance with worldwide practices for customer privacy and data protection."
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.qualityHeading}>
                        <h2>Our Quality Policy</h2>
                    </div>
                    <div className={styles.qualityContent}>
                        <h2>Quality Policy</h2>
                        <p>
                            To build a Network of Smart Mobility Fleet to enable Office
                            Commute for Corporates and Intra/Inter City Delivery of Goods
                            for Businesses.
                        </p>
                        <p className={styles.para}>– CEO : Rakesh Munnanooru</p>

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default QualityPolicy
