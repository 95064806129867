import React from 'react'
import styles from './WhistleElectric.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';
// import CountUp from 'react-countup';
// import ScrollTrigger from 'react-scroll-trigger';
// import { useState, useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import { Helmet } from 'react-helmet-async';

const logos = [
    { name: 'whistledrive-invesco-logo', id: 1 },
    { name: 'whistledrive-novartis-logo', id: 2 },
    { name: 'whistledrive-one-plus-logo', id: 3 },
    { name: 'whistledrive-examity-logo', id: 4 },
    { name: 'whistledrive-L&T-logo', id: 5 },
    { name: 'whistledrive-ADP-logo', id: 6 },
]

const urbanContent = [
    { img: 'ElectricFleet', text: '100% Electric Fleet', id: 1 },
    { img: 'ZeroPollution', text: 'Zero Pollution', id: 2 },
    { img: 'AI&ML', text: 'AI & ML Powered Tech Platform', id: 3 },
    { img: 'CostSavings', text: 'Cost Savings (Up to 30%)', id: 4 },
]

const drivenContent = [
    { text: 'Scheduling', id: 1 },
    { text: 'Live Tracking', id: 2 },
    { text: 'Safety Alerts', id: 3 },
    { text: 'Generate Insightful Reports', id: 4 },
]

function WhistleElectric() {
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>
                    Electric Fleet Services | Transportation & Intracity Logistics
                </title>
                <meta
                    name="description"
                    content="Helping businesses to adopt electric fleet for corporate employee transportation and intracity logistics"
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.subContent}>
                            <h5><span>Whistle</span>Electric</h5>
                            <h1 className={styles.fullStack}>Mobility Solutions for Sustainable Cities</h1>
                            <p className={styles.paragraph}>Enabling businesses to adopt sustainable transportation for their employee transportation and goods transportation needs using Electric Vehicles.</p>
                            <Link to="/contact-us">Contact Us</Link>
                        </div>
                        <div className={styles.mainImgContainer}>
                            <img src='../assests/images/Whistle-Electric/whistle-electric-img.png' alt='whistle-electric-img.png' />
                        </div>

                        {/* <div className={styles.videoContainer}>
                            <video autoPlay loop muted style={{ width: '100%' }}>
                                <source src="../assests/images/home-page gif.mp4" type="video/mp4" />
                            </video>
                        </div> */}

                    </div>
                    <div className={styles.brandContainer}>
                        <div className={styles.brandImgsContainer}>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name}/>
                                ))}
                            </div>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name}/>
                                ))}
                            </div>

                        </div>
                    </div>
                    <hr className={styles.line1} />
                    <div className={styles.helpingContainer}>
                        <h3>
                            Helping the businesses move in a sustainable path
                        </h3>
                        <p className={styles.transportText}>
                        We provide an Environment Friendly Electric Fleet  powered by an Intelligent Tech Platform. 
                        </p>
                        <div className={styles.transportationContainer}>
                            <div className={styles.transportCard}>
                                <img src='../assests/images/Whistle-Electric/electric-car.png' alt='electric-car' />
                                <div className={styles.transportDetails}>
                                    <h5>Cabs</h5>
                                    <p className={styles.transportText}>
                                        Electric Cabs for Employee Pickup/Drop Services
                                    </p>
                                </div>

                            </div>
                            <div className={styles.transportCard}>
                                <img src='../assests/images/Whistle-Electric/electric-auto.png' alt='electric-auto' />
                                <div className={styles.transportDetails}>
                                    <h5>3-Wheeler Cargo</h5>
                                    <p className={styles.transportText}>
                                        Electric 3-wheeler vehicles for Last-mile order deliveries.
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className={styles.urbanNeedsContainer}>
                        <h2>
                            Sustainable and Smart Solutions powering the urban needs of tomorrow, one step at a time
                        </h2>
                        <p className={styles.paragraph} style={{ textAlign: 'start' }}>
                            Our goal is to provide answers that benefit our customers and build future-ready mobility models for businesses.
                        </p>
                        <ul className={styles.urbanNeedslist}>
                            {urbanContent.map((eachItem) => {
                                return (
                                    <li className={styles.urbanNeedsCard} key={eachItem.id}>
                                        <img src={`../assests/images/Whistle-Electric/${eachItem.img}.png`}  alt='electric'/>
                                        <h5>{eachItem.text}</h5>
                                    </li>
                                )
                            })}
                        </ul>
                        <div className={styles.contactUsContainer}>
                            <Link to="/contact-us" className={styles.contactUs}>Contact Us</Link>


                        </div>


                    </div>
                    <div className={styles.ecoSystemContainer}>
                        <h2>A connection between us and the ecosystem  </h2>
                        <p >
                            Bridging the gap in the fragmented market, our platform believes in adding value to several
                            stakeholders in the ecosystem and creating a healthier and sustainable supply chain.
                        </p>
                        <div className={styles.ecosystemImgContainer} >
                            <div className={styles.ecosystemImg}>
                                <img src='../assests/images/Whistle-Electric/treesimg.png' alt='trees'/>

                            </div>
                            <div className={styles.ecosystemImg}>

                                <img src='../assests/images/Whistle-Electric/ecosystem.png' alt='ecosystem'/>


                            </div>



                        </div>

                    </div>
                    <div className={styles.drivenContainer}>
                        <div className={styles.drivenContent}>
                            <h2>Driven by Intelligent Technology</h2>
                            <h5>Visibility and Predictably at the core.</h5>
                            <p className={styles.paragraph}>
                                With of aim of providing end-to-end visibility of transport
                                operations, we provide an intelligent technology that helps in
                                streamlining the complete transportation process
                            </p>
                            <ul className={styles.drivenContentList}>

                                {drivenContent.map((eachItem) => {
                                    return (
                                        <li key={eachItem.id}>
                                            <h3>
                                                <i className={`fa-solid fa-circle ${styles.circle}`} ></i>
                                                {eachItem.text}
                                            </h3>
                                        </li>

                                    )
                                })}


                            </ul>

                        </div>
                        <div className={styles.drivenImgContainer}>
                            <img src='../assests/images/Whistle-Electric/driven-img.png' alt='driven'/>

                        </div>

                    </div>
                    <ContactUsCard img={"ET-Contact"} text={"Lets build sustainable cities, together!"} />
                </div>
                <Footer />
            </div>
        </div>


    )
}

export default WhistleElectric
