import React, { useState, useEffect } from 'react'
import styles from './Blog.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1'
import Carousel from '../../Components/Carousel/Carousel'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


function Blog() {

    const [Et, setEt] = useState<any>("");
    const [Ws, setWs] = useState<any>("");
    const preprocessMarkdown = (markdownContent: any) => {
        // Replace **bold** with **bold**
        return markdownContent.replace(/\*\*(.*?)\*\*/g, '**$1**');
    };
    const markdown = '> **Quotehey**';

    useEffect(() => {
        const config: any = {
            method: 'get',
            url: 'http://localhost:1337/api/blogs?filters[slug][$eq]=intracity-logistics-trends',
            headers: {
                // Authorization: "vgz2MtyLpVKBx1FVZ176CNp7XWUPpikK",
                'Content-Type': 'application/json'
            }
        }

        const employeeTransportationPosts: any = [];
        const whistleStoriesPosts: any = [];


        axios(config)
            .then((res) => {
                const processedMarkdown = preprocessMarkdown(res?.data?.data[0]?.attributes?.content);
                setEt(processedMarkdown)

                console.log(res?.data?.data)
            })
            .catch(err => {
                console.log(err)
            })

    }, [])


    return (
        <div className={styles.mainContainer}>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.carouselContainer}>
                        <h3>Blog</h3>
                        <Carousel />
                    </div>
                    <div className={styles.headings}>
                        <h4>Employee Transportation</h4>
                        <h5>See all <i className='fas fa-angle-right'></i></h5>


                    </div>
                    {/* <ul className={styles.blogsListContainer}>
                        {Et.length !== 0 ? (
                            Et.map((eachItem: any) => {
                                return (

                                    <li className={styles.blogCard}>
                                        <div>
                                            <img src={eachItem.card_img} alt='card-img'/>
                                            
                                            <h4>{eachItem.title}</h4>
                                            <div className={styles.readMoreContainer}>
                                                <Link to={`/blogDetails/${eachItem.id}`} >Read More</Link>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })) : <p>no Data</p>
                        }

                    </ul> */}

                    <hr className={styles.line1} />

                    <div className={styles.headings}>
                        <h4>Whistle Stories</h4>
                        <h5>See all <i className='fas fa-angle-right'></i></h5>


                    </div>


                    {/* <ul className={styles.blogsListContainer}>
                        {Ws.length !== 0 ? (
                            Ws.map((eachItem: any) => {
                                return (
                                    <li className={styles.blogCard}>
                                        <div>
                                            <img src={eachItem.card_img} alt='card-img' />
                                            
                                            <h4>{eachItem.title}</h4>
                                            <div className={styles.readMoreContainer}>
                                                <Link to={`/blogDetails/${eachItem.id}`} >Read More</Link>
                                            </div>
                                        </div>
                                    </li>

                                )
                            })) : <p>no data</p>}
                    </ul> */}


                </div>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {Et}
                </ReactMarkdown>

            </div>
        </div>
    )
}

export default Blog
