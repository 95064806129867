import React from 'react'
import styles from './NewsRoom.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async'

const newsLetterContent = [
    { img: 'Financial', text: 'WhistleDrive: Office commute made simple and easy', link: "https://www.financialexpress.com/industry/sme/whistledrive-office-commute-made-simple-and-easy/1820802/", id: 1 },
    { img: 'News-Minute', text: 'Hyd-based WhistleDrive announces interest free loans for cab drivers', link: "https://www.thenewsminute.com/article/hyd-based-whistledrive-announces-interest-free-loans-cab-drivers-122766", id: 2 },
    { img: 'business-line', text: 'WhistleDrive drives into urban logistics market with fleet of over 5,000 trucks', link: "https://www.thehindubusinessline.com/economy/logistics/whistledrive-drives-into-urban-logistics-market-with-fleet-of-over-5000-trucks/article33158621.ece", id: 3 },
    { img: 'people-matters', text: 'WhistleDrive’s CEO on enhancing employee transportation experience with tech.', link: "https://www.peoplematters.in/article/technology/whistledrives-ceo-on-enhancing-employee-transportation-experience-with-tech-22967", id: 4 },
]

const awardsContent = [
    { img: 'Forbes-DGEMS', year: '2023', text: 'Recognized as "Select 200 Companies with Global Business Potential" by Forbes India DGEMS 2023 🏆.', id: 1 },
    { img: 'TIE', year: '2022', text: 'Recognized as "TGS100 Emerging Startups Globally', id: 2 },
    { img: 'TS', year: '2022', text: `Recognized as one of Telangana's most innovative and emerging startups by Hon'ble Chief Minister of Telangana K Chandrashekar Rao Garu at the inauguration of the world's largest innovation campus, T-Hub. 🚀`, id: 3 },
    { img: 'Forbes', year: '2021', text: 'Rakesh Munnanooru, Our Founder & CEO has been accepted to Forbes Technology Council representing WhistleDrive at one of elite technology communities in the world.', id: 4 },
    { img: 'Tuv', year: '2020', text: '  Awarded ISO 9001 Certification for our commitment to providing high-quality services.', id: 5 },
    { img: 'wim', year: '2019', text: ' Recognized as “The Next Global Tech 50” at the World Innovators Meet 2019.', id: 6 },
    { img: 'Business-Connect', year: '2019', text: 'Selected as one of “The 50 Emerging Startups in India – 2019” by Business Connect Magazine', id: 7 },
    { img: 'siliconindia', year: '2018', text: 'Selected as one of the “10 Best Startups in Hyderabad – 2018” by Silicon India.', id: 8 },
]

function NewsRoom() {
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>News Room | Updates - WhistleDrive</title>
                <meta
                    name="description"
                    content="Never miss an update. Readout where WhistleDrive is streaming now. Get notified on all the WhsitleDrive news updates, videos here. #JustWhistle"
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.newsRoomHeadings}>
                        <h5>WhistleDrive in the News!</h5>

                        <h2>Don't take our word for it.</h2>

                    </div>
                    <div className={styles.newsLetterContainer}>
                        {newsLetterContent.map((eachItem) => {
                            return (
                                <div className={styles.newsLetterCard}>
                                    <img src={`../assests/images/NewsRoom/${eachItem.img}.png`} alt='news-letter' />
                                    <div className={styles.newsLetterContent}>
                                        <h5>{eachItem.text}</h5>
                                        <a
                                            href={eachItem.link}
                                        >Know More <i className="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </div>

                                </div>

                            )
                        })}


                    </div>
                    <div className={styles.awardsHeading}>
                        <h2>Awards & Recognitions</h2>
                    </div>
                    <ul className={styles.awardsContainer}>
                        {awardsContent.map((eachItem) => {
                            return (
                                <li className={styles.awardsCard} key={eachItem.id}>
                                    <img src={`../assests/images/NewsRoom/${eachItem.img}.png`} alt='awards' />
                                    <div className={styles.awardContent}>
                                        <h5>{eachItem.year}</h5>
                                        <p>
                                            {eachItem.text}
                                        </p>
                                    </div>
                                </li>

                            )
                        })}


                    </ul>
                </div>
                <Footer />

            </div>
        </div>
    )
}

export default NewsRoom
