import React from 'react'
import styles from './PrivacyPolicy.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async'

function PrivacyPolicy() {
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Privacy Policy - WhistleDrive</title>
                <meta
                    name="description"
                    content="Your privacy is important to you and to us. So we’ll protect the information you share with us. To protect your privacy, the Site follows different principles in accordance with worldwide practices for customer privacy and data protection."
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.privacyHeading}>
                        <h2>Privacy Policy</h2>

                    </div>
                    <div className={styles.privacyContent}>
                        <h5>WhistleDrive</h5>
                        <p>
                            Whistle Drive is committed to protecting the privacy and security
                            of the data of its clients and associates (hereafter, “Users”). We
                            want you to understand how we collect and use information through
                            our product and services (hereafter, “Service and mobile
                            application”) so you can interact with WhistleDrive with
                            confidence. By using Whistle Drive’s Service you accept and agree
                            to the practices this Privacy Policy describes.
                        </p>

                    </div>
                    <div className={styles.privacyContent}>
                        <h5>Information We Collect</h5>
                        <p>
                            When you use our Service, we may collect and use personally
                            identifiable information about the employee, as well as other
                            information. More specifically, when you use our Service, we may
                            collect the following information: Information relating to
                            employee, including employee name, Employee ID, email address,
                            mobile number, pickup location (could be home address or common
                            pickup area), Gender, Employee emergency contact (2 contacts to
                            reach out during emergency). If mobile application is used, we
                            capture the make and model of the mobile device used.
                        </p>
                    </div>
                    <div className={styles.privacyContent}>
                        <h5>Use of Your Information</h5>
                        <p>
                            We use the information we collect from you primarily to enable us
                            to provide our services in the best possible manner, Your
                            information may be used for the following purposes:
                        </p>
                        <ul>
                            <li>
                                Email address and mobile: To send communication about the
                                transportation;
                            </li>
                            <li>
                                Geographic coordinates: To provide transportation between your
                                location and office;
                            </li>
                            <li>Emergency contacts: To reach out in case of SOS call;</li>
                            <li>
                                Gender: For assigning an Escort for female employees and provide
                                security;
                            </li>
                            <li>
                                Mobile device make and model: To capture app compatibility;
                            </li>
                        </ul>
                    </div>
                    <div className={styles.privacyContent}>
                        <h5>Security</h5>
                        <p>
                            We may share your personal information as follows: We may share
                            your personal information with trusted third parties or our
                            affiliates to assist us in providing our service (a “Business
                            Purpose”). Such third parties have access to personal information
                            as needed to perform their functions, but they are not allowed to
                            use it for other purposes. When we disclose personal information
                            for a Business Purpose, we enter a contract that describes the
                            purpose and requires the recipient to both keep that personal
                            information confidential and not use it for any purpose except
                            performing the contract. We share your personal information with
                            the following categories of third parties:
                        </p>
                        <ul>
                            <li>Service providers for location tracking</li>
                            <li>Service providers for number masking</li>
                        </ul>
                        <p>
                            We do not sell or rent personal information collected through
                            WhistleDrive to anyone.
                        </p>
                        <p>
                            Other circumstances: We may disclose your personal information to
                            third parties if we are involved in a merger, acquisition, or sale
                            of any or all of our business and/or our assets to a third party,
                            or if we have a good faith belief that disclosure is necessary to:
                        </p>
                        <p>
                            Comply with applicable laws, regulations, legal process, or lawful
                            requests by public authorities, including to meet national
                            security or law enforcement requirements;
                        </p>
                        <p>
                            Enforce applicable Terms of Service, including investigation of
                            potential violations of such Terms, or to detect, prevent, or
                            otherwise address fraud, security, or technical issues.
                        </p>
                    </div>
                    <div className={styles.privacyContent}>
                        <h5>Privacy Policy Updates</h5>
                        <p>The privacy policy was last updated on March 15, 2020</p>
                        <p>
                            We update the Privacy Policy from time to time, so please review
                            it regularly. If we materially change our Privacy Policy, we will
                            notify you by contacting you through your user account email
                            address or by posting a notice on our Website and request new
                            consent. If WhistleDrive wishes to disclose personal information
                            collected to a third party, appropriate notice and choice will be
                            issued to the individuals for whom the personal information
                            pertains to. Your continued use of the Service will be deemed your
                            agreement that your information may be used in accordance with the
                            new policy. If you do not agree with the changes, then you should
                            stop using the Service and notify us that you do not want your
                            information used in accordance with the changes.
                        </p>
                    </div>
                    <div className={styles.privacyContent}>
                        <h5>Contacting Us</h5>
                        <p>
                            We welcome any questions, comments, or complaints you may have
                            regarding this Privacy Policy. Please do not hesitate to contact
                            us at:
                        </p>
                        <p>WhistleDrive Private Limited,</p>
                        <p>4A, 4TH Floor, Trendz Jr, Plot no 23 & 24,</p>
                        <p>Gafoor Nagar Madhapur, Hyderabad,</p>
                        <p>Madhapur, Telangana 500081</p>
                        <p>
                            <a href="mailto:hello@whistledrive.com">hello@whistledrive.com</a>
                        </p>
                        <p><a href="tel:8499040404">+91 8499 04 04 04</a></p>
                    </div>

                </div>
                <Footer />

            </div>
        </div>
    )
}

export default PrivacyPolicy
