import React from 'react'
import './Navbar1.css'
import { Link } from 'react-router-dom';
import { useState } from 'react'

function Navbar1() {
    const [isOpen, setIsOpen] = useState(false);
    const [dropDown, setDropDown] = useState<any>(null);
    const [mobileDropDown, setMobileDropDown] = useState<any>(false);


    console.log(mobileDropDown, "mobileDropDown");

    return (
        <div className='nav-container'>

            <div className='nav'>
                <Link to="/" className='logo-container'>
                    <img src='../assests/images/whistle-drive-logo.png' alt='whistle-drive-logo' className="logo" />

                </Link>

                <ul className='nav-items'>
                    <li onMouseEnter={() => setDropDown("Et")} onMouseLeave={() => setDropDown(null)} className='nav-list-item'>
                        <Link to="/employee-transportation" className='nav-item'>Employee Transportation</Link>
                        {dropDown === "Et" && (
                            <div className="dropdown" style={{ width: '13%' }}>
                                <Link to="/os-for-mobility ">OS For Mobility</Link>
                                <Link to="/executive-car-rentals" >Executive Car Rentals</Link>

                            </div>

                        )}

                    </li>
                    <li onMouseEnter={() => setDropDown("El")} onMouseLeave={() => setDropDown(null)} className='nav-list-item'>
                        <Link to="/enterprise-logistics" className='nav-item'>Enterprise Logistics</Link>
                        {dropDown === "El" && (
                            <Link to="/os-for-logistics" className="dropdown">OS For Logistics</Link>
                        )}

                    </li>
                    <li onMouseEnter={() => setDropDown("Wl")} onMouseLeave={() => setDropDown(null)} className='nav-list-item'>
                        <Link to="/electric-fleet-services" className='nav-item'>Whistle Electric</Link>
                        {dropDown === "Wl" && (
                            <Link to="" className="dropdown" style={{ width: '8%', padding: '0px' }}></Link>
                        )}

                    </li>
                    <li onMouseEnter={() => setDropDown("El")} onMouseLeave={() => setDropDown(null)} className='nav-list-item'>
                        <Link to="" className='nav-item'>Company </Link>
                        {dropDown === "El" && (
                            <div className="dropdown">
                                <Link to="/about-us" >About Us</Link>
                                {/* <Link to="/blog" >Blog</Link> */}
                                <Link to="/news-room">Newsroom</Link>
                            </div>

                        )}

                    </li>
                    <li className='contact-us-btn'>
                        <Link to="/contact-us" className='nav-item'>Contact us</Link>

                    </li>
                </ul>
                <button className='nav-icon' onClick={() => setIsOpen(!isOpen)}>
                    <span className={`nav-line ${isOpen ? "open" : ''}`}></span>
                    <span className={`nav-line ${isOpen ? "open" : ''}`}></span>
                    <span className={`nav-line ${isOpen ? "open" : ''}`}></span>
                </button>
                <ul className={`mobilenavItems ${isOpen ? "open" : ""}`}>
                    <li className={`mobile-nav-item ${isOpen ? "open" : ''}`}>
                        <Link to="/employee-transportation">Employee Transportation</Link>
                        <Link to="/os-for-mobility ">OS For Mobility</Link>
                        <Link to="/executive-car-rentals">Executive Car Rentals</Link>
                    </li>
                    <li className={`mobile-nav-item ${isOpen ? "open" : ''}`}>
                        <Link to="/enterprise-logistics">Logistics Transportation</Link>
                        <Link to="/os-for-logistics">OS For Logistics</Link>
                    </li>
                    <li className={`mobile-nav-item ${isOpen ? "open" : ''}`}>
                        <Link to="/electric-fleet-services">Whistle Electric</Link>
                    </li>
                    <li className={`mobile-nav-item ${isOpen ? "open" : ''}`}>
                        <h3 onClick={() => setMobileDropDown(!mobileDropDown)}>Company <i className="fa fa-caret-down" aria-hidden="true"></i></h3>
                        {mobileDropDown === true ? (
                            <div className='mobile-drop-down'>
                                <Link to="/about-us">About Us</Link>
                                {/* <Link to="/blog">Blog</Link> */}
                                <Link to="/news-room">NewsRoom</Link>

                            </div>
                        ) : ""}
                    </li>
                    <li className={`mobile-nav-item ${isOpen ? "open" : ''}`}>
                        <Link to="/contact-us" className='contact-us-btn' style={{ color: 'white' }}>Contact Us</Link>
                    </li>

                </ul>

            </div>
        </div>
    )
}

export default Navbar1
