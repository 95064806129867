import React, { useState } from 'react'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'
import axios from 'axios';

import { useFormik } from 'formik';
import * as yup from "yup";

const initialValues = {

    email: '',
}

const validationSchema = yup.object().shape({
    email: yup.string().email().required("Email is Required"),
})

function Footer() {

    const [success, setSuccess] = useState(false);




    const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        // setFieldValue

    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            console.log(values.email, "email")
            var apiKey = "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";
            var senderEmail = values.email;



            var data = {
                sender: { email: senderEmail },
                to: [
                    { email: "prashanth@whistledrive.com" },
                    { email: "hello@whistledrive.com" },
                    { email: "mukesh.kondal@whistledrive.com" },
                    { email: "raju.mudireddy@whistledrive.com" },
                    { email: "vinay.yarabolu@whistledrive.com" },
                    { email: "sampath.vedantam@whistledrive.com" },
                ],

                subject: "New Subscriber added",
                templateId: 2,
                params: {
                    email: values.email,
                }
            };

            const config: any = {
                method: "POST",
                url: 'https://api.sendinblue.com/v3/smtp/email',
                headers: {
                    "Content-Type": "application/json",
                    "api-key": apiKey
                },
                data: JSON.stringify(data)
            }

            axios(config)
                .then((res) => {
                    if (res.status === 201) {
                        console.log("Email sent successfully!");
                        setSuccess(true)
                        var contactData = {
                            email: values.email,
                            listIds: [5],
                            updateEnabled: true,
                            attributes: {
                                SOURCE: "News Letter",

                            }
                        }

                        const config2 = {
                            method: "POST",
                            url: 'https://api.brevo.com/v3/contacts',
                            headers: {
                                "Content-Type": "application/json",
                                "api-key": apiKey
                            },
                            data: JSON.stringify(contactData)
                        }
                        axios(config2)
                            .then((res) => {
                                console.log("contact is addedd")
                            })
                            .catch((err) => {
                                console.log("Something Went Wrong", err)

                            })
                    }
                })
                .catch((err) => {
                    alert("Something went wrong")
                })

            resetForm()

        }
    })

    return (
        <div className={styles.footerMainContainer}>
            <img src='../assests/images/whistle-Forbes.png' className={styles.forbesImg} alt='whistle-Forbes' />
            <div className={styles.footerContainer}>
                <div className={styles.subFooterContainer}>
                    <div className={styles.logoContainer}>
                        <img src='../assests/images/whistle-drive-logo.png' alt='whistle-logo' className={styles.logo} />
                        <h1>Integrated urban mobility solution</h1>
                        <h5>Awards and Recognition</h5>
                        <img src='../assests/images/awards-img.png' alt='awards' className={styles.logo} />
                    </div>
                    <div className={styles.rightFooter}>
                        <div className={styles.serviceContainer}>
                            <h2>Services</h2>
                            <Link to="/employee-transportation">
                                <h6>Employee Transportation</h6>
                            </Link>
                            <Link to="/enterprise-logistics">
                                <h6>Goods Transportation</h6>
                            </Link>

                            <Link to="/os-for-mobility ">
                                <h6>WhistleOS for Mobility</h6>
                            </Link>
                            <Link to="/executive-car-rentals">
                                <h6> Executive Car Rentals</h6>
                            </Link>
                            <Link to="/os-for-logistics">
                                <h6>WhistleOS for Transportation</h6>
                            </Link>
                            <Link to="/electric-fleet-services">
                                <h6>Whistle Electric</h6>
                            </Link>
                            <h2>Company</h2>
                            <Link to="/about-us">
                                <h6>About Us</h6>
                            </Link>

                            {/* <Link to="/blog">
                                <h6>Blog</h6>
                            </Link> */}
                            <Link to="/contact-us">
                                <h6>Contact Us</h6>
                            </Link>
                            <Link to="/news-room">
                                <h6>News Room</h6>
                            </Link>
                        </div>
                        <div className={styles.serviceContainer}>
                            <h2>Office</h2>
                            <h6>Address</h6>
                            <p>
                                4A, 4TH Floor, Trendz Jr, Plot no 23 & 24, Gafoor Nagar Madhapur,
                                Hyderabad, Madhapur, Telangana 500081
                            </p>
                            <h6>Mobile</h6>
                            <p><a href="tel:8499040404" className={styles.phone} > +918499 04 04 04 </a></p>
                            <h6>Email</h6>
                            <p>
                                <a href="mailto:hello@whistledrive.com" className={styles.phone}>hello@whistledrive.com</a>
                            </p>
                        </div>
                        <div className={styles.serviceContainer}>
                            <h2>Subscribe to our newsletter</h2>
                            <div className={styles.inputContainer}>
                                <input className={styles.input} type="email" placeholder="Your e-mail" onChange={handleChange("email")}
                                    onBlur={handleBlur("email")}
                                    value={values.email}
                                />
                                <button className={styles.arrowIcon} type="submit" onClick={() => handleSubmit()}><i
                                    className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                            {touched.email && errors.email ? (
                                <p className={styles.errorMsg} style={{ color: 'red' }}>Invalid email address</p>
                            ) : null}
                            {/* {isValid ? null : <p style={{ color: 'red' }}>Invalid email address</p>} */}
                            {success ? <p style={{ color: 'green' }}>Thanks for subscribe</p> : ""}
                            <div className={styles.MediaContainer}>
                                <h3>Social Media</h3>
                                <div className={styles.mediaImgCon}>
                                    <a href="https://www.linkedin.com/company/whistledrive" target="_blank" rel="noopener noreferrer">
                                        <img className="img-responsive media-img" src="../assests/images/linkedin.png"
                                            alt="linkedIn-img" />
                                    </a>
                                    <a href="https://www.facebook.com/whistledrive/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-responsive media-img" src="../assests/images/facebook.png"
                                            alt="facebook-img" />
                                    </a>
                                    <a href="https://www.twitter.com/WhistleDrive/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-responsive media-img" src="../assests/images/twitter.png"
                                            alt="twitter-img" />
                                    </a>
                                    <a href="https://youtube.com/@gowhistledrive/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-responsive media-img" src="../assests/images/Youtube.png"
                                            alt="youtube-img" />
                                    </a>
                                </div>
                            </div>
                            <div className={styles.appContainer}>
                                <h3>Employee App</h3>
                                <div className={styles.appImgCon}>
                                    <a href="https://apps.apple.com/in/app/whistledrive-employee-app/id1475468821" target="_blank" rel="noopener noreferrer">
                                        <img className="img-responsive media-img" src="../assests/images/app_store.png"
                                            alt="app-store" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.whistledrive.empnative&hl=en-IN" target="_blank" rel="noopener noreferrer">
                                        <img className="img-responsive media-img" src="../assests/images/play-store.png"
                                            alt="play-store" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={styles.line2} />
                <div className={styles.copyrightContainer}>
                    <a href="index.html">
                        <p>copyright © WhistleDrive 2023</p>
                    </a>
                    <Link to="/privacy-policy">
                        <p>Privacy Policy</p>
                    </Link>
                    <Link to="/quality-policy">
                        <p>Quality Policy</p>
                    </Link>
                    <Link to="/terms-and-conditions">
                        <p>Terms and Conditions</p>
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default Footer
