import React, { useState } from 'react'
import styles from './ContactUs.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1'
import { useFormik } from 'formik';
import * as yup from "yup";
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const initialValues = {
    name: '',
    mobile: '',
    email: '',
    message: ''
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    mobile: yup
        .string()
        .required()
        // .matches(/[1-9][0-9]{9}/, "Please enter valid mobile number")
        .min(10),
    email: yup.string().email().required("Email is Required"),
    message: yup.string().required()

})

const Locations = [
    { location: 'Hyderabad', id: 1 },
    { location: 'Bengaluru', id: 2 },
    { location: 'Chennai', id: 3 },
    { location: 'Pune', id: 4 },
    { location: 'Delhi NCR', id: 5 },
    { location: 'Mumbai', id: 6 },
    { location: 'Vijayawada', id: 7 },
    { location: 'Guntur', id: 8 },
    { location: 'Kurnool', id: 9 },
    { location: 'Vizag', id: 10 },
    { location: 'Ahmedabad', id: 11 },
    { location: 'Gurugram', id: 11 },
]

function ContactUs() {

    const [success, setSuccess] = useState<any>(false);
    const [errorMsg, setErrorMsg] = useState<any>(false);


    const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        // resetForm,
        setFieldValue

    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            console.log(values)
            var subject = "IMP: New Enquiry on Website";
            var apiKey = "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";

            var data = {
                sender: { email: values.email },
                to: [
                    { email: "prashanth@whistledrive.com" },
                    { email: "hello@whistledrive.com" },
                    { email: "mukesh.kondal@whistledrive.com" },
                    { email: "raju.mudireddy@whistledrive.com" },
                    { email: "vinay.yarabolu@whistledrive.com" },
                    { email: "sampath.vedantam@whistledrive.com" },
                ],
                // cc:
                subject: subject,
                templateId: 1,
                params: {
                    name: values.name,
                    email: values.email,
                    phone: values.mobile,
                    message: values.message
                }
            };
            const config: any = {
                method: "POST",
                url: 'https://api.sendinblue.com/v3/smtp/email',
                headers: {
                    "Content-Type": "application/json",
                    "api-key": apiKey
                },
                data: JSON.stringify(data)

            }

            axios(config)
                .then((res) => {
                    if (res.status === 201) {
                        console.log("Email sent successfully!");
                        setSuccess(true)

                        var contactData = {
                            email: values.email,
                            listIds: [4],
                            attributes: {
                                SOURCE: "ContactUs",
                            }
                        };

                        const config1: any = {
                            method: "POST",
                            url: "https://api.brevo.com/v3/contacts",
                            headers: {
                                "Content-Type": "application/json",
                                "api-key": apiKey
                            },
                            data: JSON.stringify(contactData)
                        }

                        axios(config1)
                            .then((response) => {
                                console.log("Contact list saved")
                            })
                            .catch((err) => {
                                console.log("Error occurred while adding recipient email to contact list:", err);
                            });
                    }
                })
                .catch(function (error) {
                    // console.log("Error occurred while sending email:", error);
                    alert("Something went wrong")
                    setErrorMsg(true)
                });

            resetForm()
        }
    })

    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Contact us- WhistleDrive</title>
                <meta name="description"
                    content="Contact us by phone or email for a demo and other queries regarding our employee transportation solutions." />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.headings}>
                        <h2>Let’s Get In Touch!</h2>
                        <h5>A member of our team will reach you out.</h5>

                    </div>
                    <div className={styles.formContainer}>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <div className={styles.inputContainer}>
                                <label htmlFor='FullName'>Full Name*</label>
                                <input
                                    type='text'
                                    className={styles.input}
                                    id='FullName'
                                    onChange={handleChange('name')}
                                    onBlur={handleBlur('name')}
                                    value={values.name}

                                />
                                {touched.name && errors.name ? (
                                    <p className={styles.errorMsg}>Name is required</p>
                                ) : null}


                            </div>
                            <div className={styles.inputContainer}>
                                <label htmlFor='Phone'>Phone Number*</label>
                                <input
                                    type='tel'
                                    className={styles.input}
                                    id='Phone'
                                    onChange={(text: any) => {
                                        const regex =
                                            /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                        const textValue = text.target.value
                                        if (regex.test(textValue.toString()) || textValue === "") {
                                            setFieldValue("mobile", textValue);
                                        }
                                        console.log(textValue)
                                        console.log(values.mobile)
                                    }}
                                    maxLength={10}
                                    onBlur={handleBlur('mobile')}
                                    value={values.mobile}
                                />
                                {touched.mobile && errors.mobile ? (
                                    <p className={styles.errorMsg}>Mobile number is required</p>
                                ) : null}

                            </div>
                            <div className={styles.inputContainer}>
                                <label htmlFor='email'>Email*</label>
                                <input
                                    type='email'
                                    className={styles.input}
                                    id='email'
                                    onChange={handleChange("email")}
                                    onBlur={handleBlur("email")}
                                    value={values.email}
                                />
                                {touched.email && errors.email ? (
                                    <p className={styles.errorMsg}>Email is required</p>
                                ) : null}

                            </div>
                            <div className={styles.inputContainer} style={{ width: '100%' }}>
                                <label htmlFor='message'>Message*</label>
                                <textarea
                                    cols={8}
                                    rows={8}
                                    className={styles.input}
                                    id='message'
                                    onChange={handleChange("message")}
                                    onBlur={handleBlur("message")}
                                    value={values.message}
                                />
                                {touched.message && errors.message ? (
                                    <p className={styles.errorMsg}>Message is required</p>
                                ) : null}

                            </div>
                            {success ? <p style={{ color: "green" }}>Thanks for Interest</p> : ""}
                            {errorMsg ? <p style={{ color: 'red' }}>Something went wrong</p> : ""}
                            <div className={styles.btnContainer}>
                                <button type='submit' className={styles.btn}>Submit</button>
                            </div>

                        </form>

                    </div>
                    <div className={styles.enquiryContainer}>
                        <h5>
                            For enquiries related to sales, please reach us on
                            sales@whistledrive.com
                        </h5>
                    </div>
                    <div className={styles.addressMainContainer}>

                        <div className={styles.addressContainer}>
                            <h4>Corporate Head Office</h4>
                            <p >
                                <span >Address - Hyderabad</span><br />
                                4A, 4TH Floor, Trendz Jr, Plot no 23 & 24, Gafoor Nagar Madhapur, Hyderabad, Madhapur, Telangana 500081
                            </p>
                            <p>
                                <span>Mobile:</span>
                                +91 8499 04 04 04
                            </p>
                            <p >
                                <span>Email:</span>
                                hello@whistledrive.com
                            </p>

                        </div>
                        <img src='../assests/images/map-location.png' alt='map' />

                    </div>
                    <div className={styles.locatedContainer}>
                        <div className={styles.locationsContainer}>
                            <h4>We are located in</h4>
                            <ul className={styles.locationsContent}>
                                {Locations.map((eachItem) => {
                                    return (
                                        <li>
                                            <h5>{eachItem.location}</h5>
                                        </li>
                                    )
                                })}

                            </ul>

                        </div>
                        <div className={styles.imgContainer}>
                            <img src='../assests/images/Geographical-Expansion.png' alt='Geographical-Expansion' />

                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ContactUs
