import React from 'react'
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import styles from './Carousel.module.css'

function Carousel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        // autoplaySpeed: 3000,
    };
    return (
        <div className={styles.carouselContainer}>
            <Slider {...settings}>
                <div>
                    <img src="../assests/images/blog-last-mile.png" alt="Image 1" className={styles.img} />
                    
                </div>
                <div>
                    <img src="../assests/images/blog-real-time-com.png" alt="Image 2" className={styles.img} />
                </div>
                {/* <div>
                    <img src="./image3.jpg" alt="Image 3" />
                </div> */}
                {/* Add more slides as needed */}
                
            </Slider>
        </div>
    )
}

export default Carousel
