import React from 'react'
import styles from './TeamCard.module.css'

function TeamCard({ cardDetails }: any) {
    return (
        <li className={styles.teamMemberCard}>
            <img src={`../assests/images/AboutUs/${cardDetails.img}.png`} alt={cardDetails.name} />
            <h4>{cardDetails.name}</h4>
            <p className={styles.paragraph}>{cardDetails.designation}</p>

        </li>
    )
}

export default TeamCard
