import React from 'react'
import styles from './CorporateTransportation.module.css';
import Navbar1 from '../../Components/Navbar1/Navbar1';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import { Helmet } from 'react-helmet-async';



const logos = [
    { name: 'whistledrive-invesco-logo', id: 1 },
    { name: 'whistledrive-novartis-logo', id: 2 },
    { name: 'whistledrive-one-plus-logo', id: 3 },
    { name: 'whistledrive-examity-logo', id: 4 },
    { name: 'whistledrive-L&T-logo', id: 5 },
    { name: 'whistledrive-ADP-logo', id: 6 },
]
const intergratedSolution = [
    { img: 'integrated-transportation-car', head: 'Cars', text: 'Industry Compliant Cabs for Employee Transport.', id: 1 },
    { img: 'integrated-transportation-Bus-img', head: 'Bus', text: ' Buses for Point Pickups and Last-mile Connectivity.', id: 2 },
    { img: 'integrated-transportation-suv', head: 'Executive Car Rentals', text: ' Executive Business Travel and Airport Pickup & Drop.', id: 3 },
    { img: 'integrated-transportation-analytics-img', head: 'Technology Platform', text: 'Full-stack technology solution to digitize your transport operations.', id: 4 },
]

const whyUsCardContent = [
    {
        heading: 'Self-Rostering', description: ' Schedule your trips! With our application, employees can schedule their trips based on their work shifts, including ad- hoc requests.', id: 1
    },
    { heading: 'Route Planning', description: 'Highly optimised routes! Our routing algorithms will map the best routes considering employees’ locations, shifts, travel time, seat occupancy and distance.', id: 2 },
    { heading: 'Fleet On-Boarding', description: ' We keep it transparent! We on-board the fleet which is only 100% compliant according to the industry standards and we train the partners on using our WhistleOS.', id: 3 },
    { heading: 'Cab Assignment', description: 'Depending on the number of employees on the route, the right vehicle variants (Hatchback, Sedan & SUV) are engaged to ensure higher seat occupancy & cost optimisation.', id: 4 },
    { heading: 'Command Centre', description: ' Our Central vigilance team will monitor the entire activity of the transportation and ensures safety of employees 24/7.', id: 5 },
    { heading: 'Reports & Billing', description: 'From advanced reports to customised bills, the stakeholders can generate it all on a monthly or weekly basis from their dashboards.', id: 6 },
]

const redefiningContent = [
    { text: '3-Layer Driver & Vehicle Verification Process', id: 1 },
    { text: 'Dedicated Ground Operations Personnel', id: 2 },
    { text: 'Regular EHS & Compliance Checks', id: 3 },
]

const safetyContent = [
    { img: 'number-masking', title: 'Number Masking', text: 'To ensure employee privacy, all the calls between employees and drivers are connected with a bridge number and are connected only via application during the ride.', id: 1 },
    { img: 'sos', title: 'SOS Alert', text: ' In case of any emergencies, the employees can use the SOS feature to alert the admins with your location details and also your live location will be shared with all the emergency contacts simultaneously. i.e., friends/family', id: 2 },
    { img: 'safe-dropoff', title: 'Location Sharing with Friends & Family', text: ' The in-app location sharing option will help the employees to share their live location with their friends and family.', id: 3 },
    { img: 'speed-limit', title: 'Speed Limit', text: 'With the help of technology, we monitor the vehicle speed and make sure everything is under guidelines.', id: 4 },
    { img: 'Share-location', title: 'Safe Drop Confirmation', text: 'With the help of an automated protocol, we can ensure the safe drop of employees commuting in after the normal hours,especially female employees.', id: 5 },
    { img: '24x7-help', title: '24/7 Helpline (Chat, Call, Email)', text: ' We have a dedicated support team who will be available at all times to support and guide the employees on their journey.', id: 6 },
]

function CorporateTransportation() {
    const [counterOn, setcounterOn] = useState<any>(false);
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Whistle 360 | A Corporate Transportation Management System</title>
                <meta
                    name="description"
                    content="Transportation management solution for vendor onboarding, cab compliance check, route planning, service level quality check, reports & billing."
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.subContent}>
                            <h5>Reimagining Employee Commute</h5>
                            <h1 className={styles.fullStack}> End-to-End Solution for Corporate Transportation</h1>
                            <p className={styles.paragraph}>Customized Solutions for a safer & seamless employee transportation and executive car rentals experience for your executives.</p>
                            <Link to="/contact-us">Contact Us</Link>
                        </div>
                        <div className={styles.mainImgContainer}>
                            <img src='../assests/images/Employee-Transportation.png' alt='Employee-Transportation-image' />
                        </div>

                        {/* <div className={styles.videoContainer}>
                            <video autoPlay loop muted style={{ width: '100%' }}>
                                <source src="../assests/images/home-page gif.mp4" type="video/mp4" />
                            </video>
                        </div> */}

                    </div>
                    <div className={styles.brandContainer}>
                        <div className={styles.brandImgsContainer}>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                                ))}
                            </div>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                                ))}
                            </div>

                        </div>
                    </div>
                    <hr className={styles.line1} />
                    <div className={styles.integratedContainer}>
                        <h2>One stop Solution, for your end-to-end Employee Transportation needs.</h2>
                        <p className={styles.paragraph} style={{ textAlign: 'center' }}>
                            The daily commute of your employees is now made easy with our
                            fully managed employee transportation services.
                        </p>
                        <ul className={styles.integrateSolution}>
                            {intergratedSolution.map((eachItem) => {
                                return (
                                    <li className={styles.solutionContainer} key={eachItem.id}>
                                        <img src={`../assests/images/${eachItem.img}.png`} alt={eachItem.img} />
                                        <div className={styles.solutionContent}>
                                            <h4>{eachItem.head}</h4>
                                            <p className={styles.paragraph}>
                                                {eachItem.text}
                                            </p>
                                        </div>
                                    </li>
                                )
                            })}

                        </ul>

                    </div>
                    <div className={styles.whyUsContainer}>
                        <div className={styles.whyUsContent}>
                            <h3>Why us?</h3>
                            <h4>It’s more than just Cabs and Technology</h4>
                            <p className={styles.paragraph}>
                                With our full-stack employee transportation model, WhistleDrive
                                will be your SPOC (Single Point of Contact) for your entire
                                transportation operations, we own all the responsibilities on
                                your behalf.
                            </p>
                            <h6>
                                A seamless and highly-efficient transportation process to get
                                you going from day 1,
                            </h6>

                            <div className={styles.whyusContentCard}>
                                {whyUsCardContent.map((eachItem) => {
                                    return (
                                        <div className={styles.cardContent} key={eachItem.id}>
                                            <h5>{eachItem.heading}</h5>
                                            <p>
                                                {eachItem.description}
                                            </p>

                                        </div>
                                    )
                                })}


                            </div>

                            <div className={styles.technoImgContainer}>
                                <img src='../assests/images/whistle-drive-dashboard-img.png' alt='whistle-drive-dashboard-img' />
                            </div>
                        </div>
                        <div className={styles.whyUsFooter}>
                            <h4>Checkout our technology platform in detail</h4>
                            <Link to="/mobility-os">Whistle OS for Mobility <i className="fa-solid fa-arrow-right"></i></Link>

                        </div>

                    </div>
                    <div className={styles.percentageContainer}>
                        <ScrollTrigger onEnter={() => setcounterOn(true)} onExit={() => setcounterOn(false)}>
                            <div className={styles.numbersContainer}>
                                <div className={styles.numbersCard}>
                                    <h3>
                                        {counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}
                                        %

                                    </h3>
                                    <h6>Compliant Fleet</h6>

                                </div>
                                <div className={styles.numbersCard}>
                                    <h3>
                                        {counterOn && <CountUp start={0} end={96} duration={2} delay={0} />}
                                        %

                                    </h3>
                                    <h6>Efficiency</h6>

                                </div>
                                <div className={styles.numbersCard}>
                                    <h3>
                                        {counterOn && <CountUp start={0} end={95} duration={2} delay={0} />}
                                        %

                                    </h3>
                                    <h6>OTAs</h6>

                                </div>
                                <div className={styles.numbersCard}>
                                    <h3>
                                        {counterOn && <CountUp start={0} end={99} duration={2} delay={0} />}
                                        %

                                    </h3>
                                    <h6>ESAT</h6>

                                </div>
                            </div>
                        </ScrollTrigger>

                    </div>
                    <div className={styles.redefineContainer}>
                        <div className={styles.redefineContent}>
                            <h4>Redefining the Ground Operations</h4>
                            <ul className={styles.redefineContentlist}>
                                {redefiningContent.map((eachItem: any) => {
                                    return (
                                        <li key={eachItem.id}>
                                            <span><i className={`fa fa-check ${styles.tickIcon}`}></i></span>
                                            {eachItem.text}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <img src='../assests/images/redefined-ground.png' alt='redefined-ground' />

                    </div>
                    <div className={styles.executiveRentalContainer}>
                        <div className={styles.executiveContent}>
                            <h4>Executive Car Rentals</h4>
                            <p className={styles.paragraph}>
                                We provide the best-in-class executive car rental services for
                                your business/leisure travel across the city.
                            </p>
                            <p className={styles.paragraph}>
                                All our cabs are powered with Whistle Tech, which enables live
                                updates, advanced safety & security features.
                            </p>
                            <Link to="/executive-car-rentals">Know more<i className="fa-solid fa-arrow-right"></i></Link>
                        </div>
                        <img src='../assests/images/Executive-car-rentals-innova.png' alt='Executive-car-rentals-innova' />

                    </div>
                    <div className={styles.joinUscontainer}>
                        <img src='../assests/images/whistle-electric.png' alt='whistle-electric' />
                        <h3 >
                            Join us in our Sustainable Mobility Journey by Opting Electric
                            Cabs & Buses
                        </h3>
                        <Link to="/electric-fleet-services">Whistle Electric
                            <span><i className="fa-solid fa-arrow-right" style={{ paddingLeft: '5px' }}></i></span>
                        </Link>

                    </div>
                    <div className={styles.safteyContainer}>
                        <div className={styles.extraMileContainer}>
                            <img src="../assests/images/right-icon.png" alt='right-icon' />

                            <h2>Going the Extra Mile for your Employee’s Safety</h2>
                            <p className={styles.paragraph}>AI-Powered Features that Ensure Your Safety</p>

                        </div>
                        <div className={styles.safetyCard}>
                            {safetyContent.map((eachItem: any) => {
                                return (
                                    <div className={styles.safetyCardContent} key={eachItem.id}>
                                        <img src={`../assests/images/${eachItem.img}.png`} alt='safety-card' />
                                        <div className={styles.safetyContent}>
                                            <h5>{eachItem.title}</h5>
                                            <p className={styles.paragraph}>
                                                {eachItem.text}
                                            </p>
                                        </div>

                                    </div>

                                )
                            })}

                        </div>

                    </div>
                    <ContactUsCard img={"ET_and_EL"} text={"Want to know more about our End-to-End Employee Transportation solution?"} />

                </div>
                <Footer />
            </div>

        </div>
    )
}

export default CorporateTransportation
