import React from 'react'
import styles from './ContactUsCard.module.css'
import { url } from 'inspector'
import { Link } from 'react-router-dom'

function ContactUsCard({img,text}:any) {
    return (
        <div className={styles.helpusContainer} style={{ backgroundImage: `url('../assests/images/${img}.png')` }}>
            <div className={styles.helpusContent}>
                <h2>{text}</h2>
                <Link to="/contact-us" className={styles.button}>Contact Us</Link>
            </div>

        </div>
    )
}

export default ContactUsCard
