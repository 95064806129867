import React from 'react'
import styles from './EnterpriseLogistics.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import Footer from '../../Components/Footer/Footer';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import { Helmet } from 'react-helmet-async';


const logos = [
    { name: 'whistledrive-invesco-logo', id: 1 },
    { name: 'whistledrive-novartis-logo', id: 2 },
    { name: 'whistledrive-one-plus-logo', id: 3 },
    { name: 'whistledrive-examity-logo', id: 4 },
    { name: 'whistledrive-L&T-logo', id: 5 },
    { name: 'whistledrive-ADP-logo', id: 6 },
]

const powerBusinessContent = [
    { img: "Ecommerce", heading: "ECommerce", text: "Helping B2C & B2B marketplaces thrive with an extensive transportation network across cities.", id: 1 },
    { img: "QCommerce", heading: "QCommerce", text: "Customised fulfillment solutions for hyperlocal brands with a core focus on cost saving and speed.", id: 2 },
    { img: "D2C", heading: "D2C", text: " Helping new age brands in the digital marketplace thrive with flexible growth solutions like per-order and pay-per-use models with our micro-warehouse network(partnered)", id: 3 },
    { img: "3PLs", heading: "3PLs", text: "Enabling 3PLs with services like mid-mile and last-mile deliveries, all things intracity!", id: 4 },
    { img: "FMCG & Retail", heading: "FMCG & Retail", text: " Intelligent distribution solutions from storage (partnered) to B2B deliveries for consumer businesses and end consumers.", id: 5 },
    { img: "Commodities", heading: "Commodities", text: "Adaptable to their nature of operations, we help commodity businesses with intracity distribution.", id: 6 },
]

const transportingContent = [
    { img: 'firstmile', heading: 'First Mile', text: 'Retailer to the courier company. i.e Seller Pickups.', id: 1 },
    { img: 'middlemile', heading: 'Middle Mile', text: 'Origin hub/Warehouse to distribution center (DC) Or Inter-storev movements.', id: 2 },
    { img: 'lastmile', heading: 'Last Mile', text: 'Distribution Center to Customer or Store to retailer', id: 3 },
    { img: 'perunit', heading: 'Per Unit', text: 'Per packet, per tonne – Unit based deliveries.', id: 4 },
    { img: 'managedservices', heading: 'Managed Services', text: 'End-to-end management of delivery services.', id: 5 },
    { img: 'coldchain', heading: 'Cold Chain', text: 'Trucks equipped with temperature control systems', id: 6 },
]

const drivingContent = [
    { text: 'Seamless Vehicle Booking', id: 1 },
    { text: 'Real-time Location Tracking', id: 2 },
    { text: 'Customized Reports & Billing', id: 3 },
]

const fullFillmentContent = [
    { text: '<2hr & Same-day Deliveries', id: 1 },
    { text: ' Pay-per-use Flexible Fulfillment Model', id: 2 },
    { text: 'Deliveries using Electric Vehicles', id: 3 },
    { text: 'Up to 30% Cost Savings', id: 4 },
]

function EnterpriseLogistics() {
    const [counterOn, setcounterOn] = useState<any>(false);
    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Intracity Logistics powered with Micro-warehousing</title>
                <meta
                    name="description"
                    content="We provide same-day deliveries for D2C, Ecommerce, FMCG, Retail and other businesses with the help of micro-warehousing and electric vehicles."
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.subContent}>
                            <h5>All-in-one Solution</h5>
                            <h2 className={styles.fullStack}> Redefining Intracity Logistics</h2>
                            <p className={styles.paragraph}>With our micro-fulfillment centers spread across the city, businesses can save huge on logistics costs & improve order delivery times.  </p>
                            <Link to="/contact-us">Contact Us</Link>
                        </div>
                        <div className={styles.mainImgContainer}>
                            <img src='../assests/images/EnterpriseLogistics/enterprise-logistics.png' alt='Et' />
                        </div>

                        {/* <div className={styles.videoContainer}>
                    <video autoPlay loop muted style={{ width: '100%' }}>
                        <source src="../assests/images/home-page gif.mp4" type="video/mp4" />
                    </video>
                </div> */}

                    </div>
                    <div className={styles.brandContainer}>
                        <div className={styles.brandImgsContainer}>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                                ))}
                            </div>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                                ))}
                            </div>

                        </div>
                    </div>
                    <hr className={styles.line1} />
                    <div className={styles.powerBusinessContainer}>
                        <h2>Powering businesses with Specialized Mobility Solutions </h2>
                        <ul className={styles.powerBusinessCardContainer}>
                            {powerBusinessContent.map((eachItem) => {
                                return (
                                    <li className={styles.powerBusinessCard} key={eachItem.id}>
                                        <img src={`../assests/images/EnterpriseLogistics/${eachItem.img}.png`} alt='et' />
                                        <div className={styles.powerBusinessCardContent}>
                                            <h5>{eachItem.heading}</h5>
                                            <p className={styles.paragraph}>
                                                {eachItem.text}
                                            </p>
                                        </div>
                                    </li>
                                )
                            })}

                        </ul>

                    </div>
                    <div className={styles.transformingContainer}>
                        <h3>Transforming Goods Transportation with technology</h3>
                        <h4>
                            Plan & manage dispatch schedule, delivery routes, and capacity
                            in the most cost-optimized way.
                        </h4>
                        <ul className={styles.transportingMilesContainer}>
                            {transportingContent.map((eachItem) => {
                                return (
                                    <li className={styles.transportingCard}>
                                        <img src={`../assests/images/EnterpriseLogistics/${eachItem.img}.png`} alt='el' />
                                        <h5>{eachItem.heading}</h5>
                                        <p>{eachItem.text}</p>
                                    </li>


                                )
                            })}

                        </ul>

                    </div>
                    <div className={styles.drivingEfficiencyContainer}>
                        <div className={styles.drivingEfficiencyContent}>
                            <h4>
                                Driving Efficiency and Improved Cost Savings using Our Tech
                                Platform
                            </h4>
                            <p>
                                With the help of automation and artificial intelligence, our
                                technology platform enables businesses to digitize the
                                transportation operations and improve efficiency.
                            </p>
                            <ul className={styles.Contentlist}>
                                {drivingContent.map((eachItem: any) => {
                                    return (
                                        <li key={eachItem.id}>
                                            <span><i className={`fa fa-check ${styles.tickIcon}`}></i></span>
                                            {eachItem.text}
                                        </li>
                                    )
                                })}

                            </ul>
                            <div>
                                <Link to="/os-for-logistics" className={styles.linkContact}>WhistleOS for Logistics</Link>
                            </div>

                        </div>
                        <div className={styles.imgContainer}>
                            <img src='../assests/images/EnterpriseLogistics/analytics-img.png' alt='analytics' />

                        </div>

                    </div>
                    <div className={styles.d2cLogisticsContainer}>
                        <h2>
                            D2C Logistics Solution built with core focus on Faster
                            Deliveries & Cost Savings
                        </h2>
                        <div className={styles.stepsCardContainer}>


                            <div className={styles.stepsContainer}>
                                <h2>1</h2>
                                <img src='../assests/images/EnterpriseLogistics/Analyzing.png' alt='analyzing' />
                                <div>

                                    <h4>Analyzing & Recommendation</h4>
                                    <p>
                                        Based on the historic and current trends, our platform suggests
                                        locations for Inventory Placement.
                                    </p>
                                </div>

                            </div>
                            <hr className={styles.line2} />
                            <div className={styles.stepsContainer}>
                                <h2>2</h2>
                                <img src='../assests/images/EnterpriseLogistics/SmartInventory.png' alt='smart-inventory' />
                                <div>
                                    <h4>Smart Inventory Placement</h4>
                                    <p>
                                        Based on the insights, We take care of inventory placement & fulfillment on a per order & pay-per-use order models.
                                    </p>
                                </div>

                            </div>
                            <hr className={styles.line2} />
                            <div className={styles.stepsContainer}>
                                <h2>3</h2>
                                <img src='../assests/images/EnterpriseLogistics/PackDeliver.png' alt='PackDeliver' />
                                <div>
                                    <h4>Pack & Deliver</h4>
                                    <p>
                                        Automating the order management process, packaging, Labels and last-mile delivery, We get it done for you!
                                    </p>
                                </div>

                            </div>
                            <hr className={styles.line2} />
                            <div className={styles.stepsContainer}>
                                <h2>4</h2>
                                <img src='../assests/images/EnterpriseLogistics/TrackingReports.png' alt='TrackingReports' />
                                <div>
                                    <h4>Tracking & Reports</h4>
                                    <p>
                                        The customers are informed via SMS and email regarding the order status and also businesses can look at the timely reports.
                                    </p>
                                </div>

                            </div>
                        </div>
                        <p>
                            *All warehousing & fulfillment operations are handled by our close-partnered network at an optimized cost.
                        </p>

                    </div>
                    <div className={styles.percentageContainer}>
                        <ScrollTrigger onEnter={() => setcounterOn(true)} onExit={() => setcounterOn(false)}>
                            <div className={styles.numbersContainer}>
                                <div className={styles.numbersCard}>
                                    <h3>
                                        {counterOn && <CountUp start={0} end={30} duration={2} delay={0} />}
                                        %

                                    </h3>
                                    <h6>Cost Savings</h6>

                                </div>
                                <div className={styles.numbersCard}>
                                    <h3>
                                        <span>{`<`}</span>
                                        {counterOn && <CountUp start={0} end={2} duration={2} delay={0} />}
                                        hr

                                    </h3>
                                    <h6>required to fulfill on-demand requests</h6>

                                </div>
                                <div className={styles.numbersCard}>
                                    <h3>
                                        {counterOn && <CountUp start={0} end={96} duration={2} delay={0} />}
                                        %

                                    </h3>
                                    <h6>OTAs</h6>

                                </div>
                            </div>
                        </ScrollTrigger>

                    </div>
                    <div className={styles.fulfillmentContainer}>
                        <div className={styles.fulfillmentContent}>
                            <h4>
                                Our fulfilment centers help businesses with 3X faster
                                shipments
                            </h4>
                            <p>
                                All our micro-fulfillment centers are built strategically in
                                proximity to the high-density areas like gated communities,
                                residential apartments, tec parks etc..within the city.
                            </p>
                            <ul className={styles.Contentlist}>
                                {fullFillmentContent.map((eachItem: any) => {
                                    return (
                                        <li key={eachItem.id}>
                                            <span><i className={`fa fa-check ${styles.tickIcon}`}></i></span>
                                            {eachItem.text}
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>
                        <img src='../assests/images/EnterpriseLogistics/Our-fulfillment.png' alt='fulfillment' />

                    </div>
                    <div className={styles.electricFleet}>
                        <img src='../assests/images/whistle-electric.png' alt='whistle-electric' />
                        <h3 >Electric Fleet for Last-Mile Deliveries </h3>
                        <h4>
                            We deploy sustainable electric 3-wheelers and bikers as required to optimize delivery costs.
                        </h4>
                        <Link to="/electric-fleet-services">Whistle Electric
                            <span><i className="fa-solid fa-arrow-right" style={{ paddingLeft: '5px' }}></i></span>
                        </Link>

                    </div>
                    <ContactUsCard img={"ET-Contact"} text={"Want to know how we can transform your Goods Transportation?"} />

                </div>
                <Footer />

            </div>
        </div>
    )
}

export default EnterpriseLogistics
