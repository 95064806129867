import React, { useState } from 'react'
import styles from './Landing.module.css'
import Navbar1 from '../../Components/Navbar1/Navbar1';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import AnimatedText from '../../Components/AnimationText/AnimationText';
import { Helmet } from 'react-helmet-async';


const logos = [
  { name: 'whistledrive-ADP-logo', id: 1 },
  { name: 'whistledrive-novartis-logo', id: 2 },
  { name: 'whistledrive-dupont-logo', id: 3 },
  { name: 'whistledrive-amazon-logo', id: 4 },
  { name: 'whistledrive-myntra-logo', id: 5 },
]

const whistleOs = [
  { text: 'Smart Routing Algorithm', id: 1 },
  { text: 'Live Tracking (Cabs, Bus and Trucks)', id: 2 },
  { text: 'Seamless Vehicle Request Process', id: 3 },
  { text: ' In-app Trip Communication', id: 4 },
  { text: 'Advanced Safety & Security Features', id: 5 },
  { text: 'Intelligent Reports & Analytics', id: 6 },
  { text: 'Digitized Billing & Invoicing', id: 7 },
]

const EmployeeTransportation = [

  { heading: 'Cabs', desc: 'Industry Complaint Cabs for Employee Transport.', id: 1 },
  { heading: 'Bus', desc: 'Buses for Point Pickups and Last-mile Connectivity.', id: 2 },
  { heading: 'Executive Car Rentals', desc: 'Executive Business Travel and Airport Pickup & Drop.', id: 3 },
  { heading: 'Technology', desc: 'Full-stack technology solution to digitize your transport operations', id: 4 },

]

const GoodsTransportation = [
  { heading: 'Transporation/Delivery', desc: 'Mid Mile, Last Mile and B2B Transportation.', id: 1 },
  { heading: 'Tech-Integrated Solutions', desc: 'WMS & OMS Integration and Transportation Tech.', id: 2 },
]


export default function Landing() {
  const [counterOn, setcounterOn] = useState<any>(false);
  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <title>WhistleDrive | Full-stack Urban Logistics Platform</title>
        <meta
          name="description"
          content="Unlock seamless urban mobility with WhistleDrive – your go-to full-stack platform for Employee Transportation and Enterprise Logistics."
        />

      </Helmet>
      <Navbar1 />
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.mainContent}>
            <div className={styles.subContent}>
              {/* <p className={styles.connected}>A Full Stack</p> */}
              <AnimatedText />
              <h1 className={styles.fullStack}>Full Stack Urban Mobility Platform</h1>
              <p className={styles.paragraph}>Providing end-to-end employee transportation solutions and intracity transport services powered by technology.</p>
              <Link to="/contact-us">Contact Us</Link>
            </div>
            <div className={styles.videoContainer}>
              <video autoPlay loop muted style={{ width: '100%' }}>
                <source src="../assests/images/home-page gif.mp4" type="video/mp4" />
              </video>
            </div>

          </div>
          <div className={styles.brandContainer}>
            <p className={styles.trustedpara}>
              Trusted by over 200+ Top Brands in the country
            </p>
            <div className={styles.brandImgsContainer}>
              <div className={styles.brandLogoCon}>
                {logos.map((eachItem: any) => (
                  <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                ))}
              </div>
              <div className={styles.brandLogoCon}>
                {logos.map((eachItem: any) => (
                  <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                ))}
              </div>

            </div>
          </div>
          <hr className={styles.line1} />
          <div className={styles.MobilityContainer}>
            <h2>Tech Powered Employee and Logistics Solutions for Businesses</h2>
            <p className={styles.paragraph} style={{ textAlign: 'center' }}>Explore End-To-End solutions for all your mobility needs with our cutting-edge Urban Transportation services and software </p>
            <div className={styles.transportationContainer}>
              <div className={styles.transportCard}>
                <img src='../assests/images/employee-transportation-car.png' alt='employee-transportation-car' />
                <div className={styles.transportDetails}>
                  <h5>Employee Transportation</h5>
                  <p className={styles.paragraph}>
                    A unique ‘fleet + technology’ transportation service model and executive car rental services for corporates.
                  </p>
                  <Link to="/employee-transportation">Know More <i
                    className="fa-solid fa-arrow-right"></i></Link>
                </div>

              </div>
              <div className={styles.transportCard}>
                <img src='../assests/images/goods-transportation-truck.png' alt='goods-transportation-truck' />
                <div className={styles.transportDetails}>
                  <h5>Goods Transportation</h5>
                  <p className={styles.paragraph}>
                    Enabling businesses across
                    industries with reliable intracity transportation services.
                  </p>
                  <Link to="/enterprise-logistics">Know More <i
                    className="fa-solid fa-arrow-right"></i></Link>
                </div>

              </div>
            </div>

          </div>
          <div className={styles.datadrivenContainer}>
            <div className={styles.datadrivenContent}>
              <h4>Data-driven operations powered by WhistleOS</h4>
              <p className={styles.paragraph}>
                WhistleOS is our AI-powered platform developed with the core
                aim of facilitating predictability & visibility in operations.
              </p>
              <p className={styles.paragraph}>WhistleOS helps with,</p>
              <ul className={styles.whistleOslist}>
                {whistleOs.map((eachItem: any) => {
                  return (
                    <li key={eachItem.id}>
                      <span><i className={`fa fa-check ${styles.tickIcon}`}></i></span>
                      {eachItem.text}
                    </li>
                  )
                })}
              </ul>
            </div>
            <img src='../assests/images/whistle-os-img.png' alt='whistle-os' />

          </div>
          <div className={styles.datadrivenContainer}>
            <div className={styles.EtCard}>
              <h6>Employee Transportation</h6>
              <h2>Fully-managed solutions for Employee Transportation</h2>

              {EmployeeTransportation.map((eachItem: any) => {
                return (
                  <div className={styles.circleContainer} key={eachItem.id}>
                    <div className={styles.circle}><i className={`fa-solid fa-circle`}></i></div>
                    <div>
                      <h3 className={styles.cardHeading}>{eachItem.heading}</h3>
                      <p className={styles.paragraph}>{eachItem.desc}</p>
                    </div>
                  </div>
                )
              })}

            </div>
            <div className={styles.GoodsCard}>
              <h6>Goods Transportation</h6>
              <h2>Reliable Full-truck load services. Intracity!</h2>

              {GoodsTransportation.map((eachItem: any) => {
                return (
                  <div className={styles.circleContainer} key={eachItem.id}>
                    <div className={styles.circle}><i className={`fa-solid fa-circle`}></i></div>
                    <div>
                      <h3 className={styles.cardHeading}>{eachItem.heading}</h3>
                      <p className={styles.paragraph}>{eachItem.desc}</p>
                    </div>
                  </div>
                )
              })}

            </div>
          </div>
          <div className={styles.clientContainer}>
            <p className={styles.helpText}>
              Empowering businesses with safe, secure and efficient transportation solutions
            </p>

            <ScrollTrigger onEnter={() => setcounterOn(true)} onExit={() => setcounterOn(false)}>
              <div className={styles.numbersContainer}>
                <div className={styles.numbersCard}>
                  <h3>
                    {counterOn && <CountUp start={0} end={8} duration={2} delay={0} />}

                  </h3>
                  <h6>States</h6>

                </div>
                <div className={styles.numbersCard}>
                  <h3>
                    {counterOn && <CountUp start={0} end={40} duration={2} delay={0} />}
                    +

                  </h3>
                  <h6>Cities</h6>

                </div>
                <div className={styles.numbersCard}>
                  <h3>
                    {counterOn && <CountUp start={0} end={200} duration={2} delay={0} />}
                    +

                  </h3>
                  <h6>Clients</h6>

                </div>
                <div className={styles.numbersCard}>
                  <h3>
                    {counterOn && <CountUp start={0} end={75} duration={2} delay={0} />}
                    k+

                  </h3>
                  <h6>Vehicle Partners network</h6>

                </div>
                <div className={styles.numbersCard}>
                  <h3>
                    {counterOn && <CountUp start={0} end={300} duration={2} delay={0} />}
                    k+

                  </h3>
                  <h6>Deliveries</h6>

                </div>
              </div>
            </ScrollTrigger>

          </div>
          <div className={styles.urbanMobilityContainer}>
            <div className={styles.urbanMobilityCard}>
              <div className={styles.urbanMobilityContent}>
                <h6>Intelligent Product + Service Delivery!</h6>
                <h2>Rethinking Urban Mobility </h2>
                <h3>Automation-First </h3>
                <p >
                  With a core focus on automation, visibility and prediction,
                  we provide all stakeholders with adaptive and tailored mobile applications and web dashboards.
                </p>
                <h3>Multi-City Scalable Operations</h3>
                <p>
                  With the vision of producing long-term benefits for our driver partners and clients,
                  we developed robust operational models that help us scale our operations across multiple Indian cities.
                </p>
              </div>
            </div>
            <img src='../assests/images/rethinking-urban-mobility.png' alt='rethinking-urban-mobility' />
          </div>
          <div className={styles.sustainableContainer}>
            <div className={styles.sustainableContent}>
              <h6>
                The Solution for the Future and We are the catalyst of change
              </h6>
              <h2>Leading the Industry Towards Sustainable Mobility</h2>
              <p className={styles.paragraph}>
                Intra-city goods mobility and employee transportation constitute more than 30% of urban transportation.
              </p>
              <p className={styles.paragraph} style={{ paddingBottom: '20px' }}>
                As a part of our commitment towards a sustainable future,
                we are adopting an electric fleet for shorter trips and last-mile mobility.
              </p>
              <Link to="/electric-fleet-services" className={styles.button}>Whistle Electric</Link>

            </div>
            <img src='../assests/images/whistle-sustainable-mobility-cars.png' alt='whistle-sustainable-mobility-cars' />

          </div>
          <ContactUsCard img={"home"} text={"Need help with Employee Transportation or FTL Deliveries?"} />
        </div>
        <Footer />
      </div>


    </div>
  )
}
