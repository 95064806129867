import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, ScrollRestoration } from 'react-router-dom';
// import ScrollTop from 'react-router-scroll-top';
import Navbar from './Components/Navbar/Navbar';
import Landing from './Screens/Landing/Landing';
import CorporateTransportation from './Screens/CorporateTransportation/CorporateTransportation';
import OsForMobility from './Screens/OsForMobility/OsForMobility';
import Navbar1 from './Components/Navbar1/Navbar1';
import EnterpriseLogistics from './Screens/EnterpriseLogistics/EnterpriseLogistics';
import OsForLogistics from './Screens/OsForLogistics/OsForLogistics';
import WhistleElectric from './Screens/WhistleElectric/WhistleElectric';
import AboutUs from './Screens/AboutUs/AboutUs';
import NewsRoom from './Screens/NewsRoom/NewsRoom';
import ContactUs from './Screens/ContactUs/ContactUs';
import PrivacyPolicy from './Screens/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Screens/TermsAndConditions/TermsAndConditions';
import QualityPolicy from './Screens/QualityPolicy/QualityPolicy';
import Blog from './Screens/Blog/Blog';
import BlogDetails from './Screens/BlogDetails/BlogDetails';
import ExecutiveRenatls from './Screens/ExecutiveRentals/ExecutiveRenatls';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/employee-transportation' element={<CorporateTransportation />} />
        <Route path='/os-for-mobility' element={<OsForMobility />} />
        <Route path='/enterprise-logistics' element={<EnterpriseLogistics />} />
        <Route path='/os-for-logistics' element={<OsForLogistics />} />
        <Route path='/electric-fleet-services' element={<WhistleElectric />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/news-room' element={<NewsRoom />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/quality-policy' element={<QualityPolicy />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blogDetails/:id' element={<BlogDetails />} />
        <Route path='/executive-car-rentals' element={<ExecutiveRenatls />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
